import React, {
  useState,
  useEffect,
  memo,
  useRef,
  useCallback,
  useMemo,
} from "react";
import MonacoEditor from "@monaco-editor/react";
import {
  TestContainer,
  QuestionContainer,
  QuestionCard,
  CodeEditorContainer,
  TimerContainer,
  TimerIcon,
  Timer,
  ButtonContainer,
  SelectContainer,
  LoaderOverlay,
  // Button,
  PreFormattedText,
  ApiResponseContainer,
  ApiResponseHeader,
  ApiResponseContent,
  FileButton,
} from "./index.style.js";
import "../../assets/CSS/index.css";
// import Select from "react-select";
import { files, themes } from "../../Components/QuestionList/data.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  setInterviewCompleted,
  setinterviewMode,
} from "../../Redux/GlobalStates";
import { useDispatch, useSelector } from "react-redux";
import useChatLogs from "../../hook/chatStream";
import {
  Layout,
  Menu,
  Select,
  Card,
  Tabs,
  Button,
  Input,
  Tree,
  Space,
  Dropdown,
  Divider,
} from "antd";
import {
  ExportOutlined,
  GithubOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  FileOutlined,
  FolderOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { saveAs } from "file-saver";
import MDX from "../../Components/MDX.jsx";
import Modal from "../../Components/modal/index.js";
import {
  changeTab,
  codeChange,
  executeCode,
  fileSavedState,
  loadDir,
  loadFile,
  newFile,
} from "../../Redux/OctagonSlice.js";
import {
  fetchDir,
  fetchFileContent,
  saveFile,
} from "../../config/socketConfig.js";
import FileSystem from "./components/FileSystem.js";

let currentAudio = null;

const BlueCircle = () => (
  <span className="size-2 bg-blue-500 inline-block rounded-full"></span>
);

const { Header, Content, Sider } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const fetchAndPlayTTS = async (text, playTTSRef) => {
  if (!playTTSRef) return;
  if (!playTTSRef.current) return;

  try {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio = null;
    }

    const response = await axios.post(
      "https://trynexa.com/api/api/tts",
      { text },
      { responseType: "blob" }
    );

    const audioUrl = URL.createObjectURL(response.data);
    currentAudio = new Audio(audioUrl);
    currentAudio.volume = 1.0;
    currentAudio.play();
    currentAudio.addEventListener("ended", () => {
      currentAudio = null;
    });
  } catch (error) {
    console.error("Failed to fetch TTS audio:", error);
  }
};

const RealTimeResponseBox = ({
  questions,
  answers,
  techStack,
  currentQuestionIndex,
}) => {
  // const {
  //   messages,
  //   loading: evalLoading,
  //   submitPrompt,
  //   setMessages,
  //   abortResponse,
  // } = useChatCompletion({
  //   apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  //   model: "gpt-4o",
  //   temperature: 0.9,
  // });
  const {
    messages,
    forceSetMessages,
    abortResponse,
    setMessages,
    submitPrompt,
    loading,
  } = useChatLogs({ storeChats: false, url: "/dashboard/hint" });

  let promptMessage = "";

  if (questions?.length) {
    promptMessage = `TECH STACK: ${techStack} \n QUESTION: ${
      questions[currentQuestionIndex].question
    } \n CODE: ${answers[currentQuestionIndex + 1]}`;
  }

  // const promptMessages = [
  //   {
  //     role: "system",
  //     content:
  //       "In a few sentences, please give feedback on the written coding question and answer on the tech stack chosen.",
  //   },
  //   {
  //     role: "user",
  //     content: `TECH STACK: ${techStack} \n QUESTION: ${
  //       questions?.[currentQuestionIndex]?.question
  //     } \n CODE: ${answers[currentQuestionIndex + 1]}`,
  //   },
  // ];
  useEffect(() => {
    forceSetMessages([]);
  }, [currentQuestionIndex]);

  // useEffect(() => {
  //   const intervalId = setInterval(async () => {
  //     setMessages([]);
  //     submitPrompt(promptMessages);
  //   }, 60000);
  //   return () => clearInterval(intervalId);
  // }, [currentQuestionIndex]);
  // console.log(messages?.at(1)?.content);

  return (
    <ApiResponseContainer>
      <button
        className="next-button get-hint-button"
        onClick={() => {
          submitPrompt(promptMessage);
        }}
      >
        Get hint
      </button>
      <ApiResponseContent className="overflow-y-scroll">
        {loading && messages?.at(1)?.content === "" ? (
          <div className="flex justify-center mt-10">
            <img
              className="w-32"
              src="https://cdn.dribbble.com/users/3742211/screenshots/9195657/media/6796a544d6f9ef1293d8d8d9e60d38d5.gif"
              alt="loader"
            />
          </div>
        ) : (
          <div className="w-full h-[270px] text-wrap overflow-y-scroll">
            <MDX
              children={
                messages.at(1) &&
                messages.at(1).content.replaceAll("\n\n", "\n")
              }
            />
          </div>
        )}
      </ApiResponseContent>
    </ApiResponseContainer>
  );
};

// Convert remaining seconds to mm:ss format
const formatTime = (seconds) => {
  if (seconds <= 0) {
    return "00:00";
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};

const DSAQuestion = memo(({ question }) => {
  return (
    <div className="h-96 overflow-y-scroll">
      <h4 className="text-2xl my-1 font-semibold">{question?.title ?? ""}</h4>
      <div dangerouslySetInnerHTML={{ __html: question?.content }}></div>
    </div>
  );
});

const Interview = memo(({ techStack }) => {
  const playTTSRef = useRef(true);

  const [modalIsOpen, setIsOpen] = useState(false);
  // const {
  //   messages,
  //   loading: evalLoading,
  //   submitPrompt,
  // } = useChatCompletion({
  //   apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  //   model: "gpt-4o",
  //   temperature: 0.9,
  // });

  const {
    messages,
    forceSetMessages,
    abortResponse,
    setMessages,
    submitPrompt,
    getFeedback,
  } = useChatLogs({ storeChats: false, url: "/dashboard/evaluate" });
  // console.log(messages);
  const dispatch = useDispatch();
  const [realTimeResponse, setRealTimeResponse] = useState("");
  const questions = techStack;
  console.log(questions);
  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [fileName, setFileName] = useState("python");
  const state = useSelector((state) => state.octagon);
  console.log(state);
  const [theme, setTheme] = useState("vs");
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const navigate = useNavigate();
  const file = files.filter((ele) => ele.language === fileName)[0];
  const { activeFileKey, fileList } = state;
  const selectedFile =
    fileList && activeFileKey !== null ? fileList[activeFileKey] : null;
  console.log(fileList, selectedFile);
  const [selectedKeys, setSelectedKeys] = useState(["1"]);
  const [menuVisible, setMenuVisible] = useState(false);
  const getSelectedFile = (key) => fileList.filter((file) => file.key === key);

  const [feedback, setFeeback] = useState([]);
  const [feedbackLoading, setFeedbackloading] = useState(false);

  const handleFeedback = async (summary) => {
    try {
      setFeedbackloading(true);
      const resposne = await getFeedback(
        `${summary}\nconvert above summary to array of object with key name question(its inlcudes question), answer(includes code), feedback(inlcudes Evaluation or Reasoning ). Please retrun result in json format with array named "results"`
      );

      if (resposne) {
        let results = JSON.parse(resposne);

        if (results?.results) {
          setFeeback(results?.results);
        }
        if (results?.summary) {
          setFeeback(results?.summary);
        }
        if (results?.responses) {
          setFeeback(results?.responses);
        }
      }

      setFeedbackloading(false);
    } catch (error) {
      setFeedbackloading(false);
    }
  };

  useEffect(() => {
    if (modalIsOpen && !loading) {
      handleFeedback(messages.at(1)?.content);
    }
  }, [modalIsOpen, loading]);

  // useEffect(() => {
  //   // Update File Extension
  //   if (selectedFileType) {
  //     let isExistFile = files.find((e) => e.sign === selectedFileType);

  //     if (isExistFile) {
  //       let fileArr = [...fileList];
  //       fileArr[Number(selectedFile.key)].type = isExistFile.sign;
  //       fileArr[Number(selectedFile.key)].label = `question${
  //         Number(selectedFile.key) + 1
  //       }.${isExistFile.sign}`;
  //       fileArr[Number(selectedFile.key)].language = isExistFile.language;

  //       setFileList(fileArr);
  //     }
  //   }
  // }, [selectedFileType]);

  const handleChangeFile = (e) => {
    setFileName(e?.value);
  };

  const handleChangeTheme = (e) => {
    setTheme(e?.value);
  };
  console.log(theme);
  // useEffect(() => {
  //   dispatch(setInterviewCompleted(false));
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   window.addEventListener("unload", handleUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     window.removeEventListener("unload", handleUnload);
  //     // dispatch(setinterviewMode("interview_exited"));
  //     // dispatch(setInterviewCompleted(true));
  //   };
  // }, []);

  const handleBeforeUnload = (evt) => {
    evt.preventDefault();
    let msg = "You cannot exit the interview.";
    evt.returnValue = msg;
    return msg;
  };
  const handleUnload = (evt) => {
    evt.preventDefault();
    localStorage.removeItem("dev_questions");
  };

  // useEffect(() => {
  //   console.log("main useeffect");
  //   // Fetch questions from JSON data
  //   const fetchData = async () => {
  //     try {
  //       setLoading(true); // Set loading to true when fetching data
  //       // setQuestions(techStackQuestions[techStack].slice(0, 5));
  //       if (!timer) {
  //         startTimer(); // Move startTimer call here
  //       }
  //     } catch (error) {
  //       console.error("Error fetching questions:", error);
  //     } finally {
  //       setLoading(false); // Set loading to false after fetching data
  //     }
  //   };

  //   fetchData();

  //   // Cleanup function to stop the timer when component unmounts
  //   return () => {
  //     if (timer) {
  //       clearInterval(timer);
  //     }
  //   };
  // }, []); // Listen to changes in the category prop

  const handleAnswerChange = (questionId, answer) => {
    dispatch(codeChange({ questionId, code: answer }));
    // setAnswers({ ...answers, [questionId]: answer });
    // setSelectedFile((s) => ({ ...s, value: answer }));

    // let fileArr = [...fileList];

    // fileArr[Number(selectedFile.key)].value = answer;
    // setFileList(fileArr);
  };

  const submitTest = async () => {
    playTTSRef.current = false; // Disable TTS playback
    setLoading(true);
    const answersArray = Object.keys(answers).map((questionId) => ({
      [questionId]: answers[questionId],
    }));

    const promptMessage = questions
      ?.map((item, i) => {
        return `QUESTION ${i + 1}: ${JSON.stringify(item?.question)}
      CODE: ${answers[item.id] || ""}`;
      })
      .join("\n-------------------------------\n");

    console.log("promptMessage>>", promptMessage);
    try {
      setIsOpen(true);

      await submitPrompt(promptMessage);

      console.log("after prompt messages>>", messages);
    } catch (err) {
      setResponseData("Could not evaluate the test. Some error occured");
      throw err;
    } finally {
      setLoading(false); // Ensure loading state is set to false after submission
    }
  };
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current && questions.length > 0) {
      const playTTSOnLoad = async () => {
        await fetchAndPlayTTS(questions[0].question, playTTSRef);
      };
      playTTSOnLoad();
      isInitialMount.current = false; // Ensure this runs only once
    }
  }, [questions]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: state.isFocused ? "2px solid #007bff" : "2px solid #ced4da",
      boxShadow: "none",
      "&:hover": {
        border: "2px solid #007bff",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#007bff" : "white",
      color: state.isFocused ? "white" : "black",
      "&:hover": {
        backgroundColor: "#007bff",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      width: "1000px",
      height: "720px",
      // bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const editorRef = useRef(null);
  const containerRef = useRef(null);

  const handleEditorDidMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message.startsWith("ResizeObserver loop")) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.remove("style", "display: none");
        }
      }
    });
  }, []);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (editorRef.current) {
        editorRef.current.layout();
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const onChangeTab = (fileKey) => {
    let key = parseInt(fileKey);
    dispatch(changeTab(key));
    if (fileList[key].value === null) {
      fetchFileContent(fileList[key].path, (content) => {
        dispatch(
          loadFile({
            path: fileList[key].path.split("/").slice(0, -1).join("/"),
            value: content,
            fileName: fileList[key].label,
          })
        );
      });
    }
    // let idx = Number(key);

    // setSelectedFile(fileList[idx]);
  };

  // useEffect(() => {
  //   if (selectedFile) {
  //     fetchFileContent(selectedFile.label, (content) => {
  //       console.log(content);
  //       dispatch(loadFile({ fileKey: selectedFile.key, content }));
  //     });
  //   }
  // }, [selectedFile]);

  const monoEditor = useCallback(() => {
    console.log(activeFileKey, typeof activeFileKey);
    return (
      <>
        {/* {!fileList ? (
          <div>Loading...</div>
        ) : ( */}
        <>
          <Tabs
            type="editable-card"
            onChange={onChangeTab}
            defaultActiveKey="10000"
            activeKey={
              activeFileKey !== null && activeFileKey !== undefined
                ? activeFileKey.toString()
                : undefined
            }
            items={fileList.map((fileObj) => ({
              ...fileObj,
              icon: fileObj.saved ? undefined : <BlueCircle />,
              key: fileObj.key.toString(),
            }))}
            onEdit={(evt, action) => {
              if (action === "add") {
                dispatch(newFile({ name: "untitled" }));
              } else {
                // remove();
              }
            }}
          />
        </>
        {/* )} */}
        {selectedFile && (
          <button
            className="rounded-md mr-2 p-2 px-3 flex items-center gap-2 bg-white"
            onClick={() => {
              saveFile(
                {
                  path: selectedFile.path,
                  content: selectedFile.value,
                },
                () => {
                  console.log("saved");
                  if (fileList[selectedFile.key].saved === false) {
                    dispatch(fileSavedState({ fileKey: selectedFile.key }));
                  }
                }
              );
            }}
          >
            {selectedFile.saved === false && <BlueCircle />}
            Save
          </button>
        )}

        <div
          className={`editor-wrapper h-full ${
            theme === "vs-dark" ? "dark" : ""
          }`}
          style={{ flexGrow: 1 }}
        >
          <div className={`top-bar ${theme === "vs-dark" ? "dark" : ""}`}>
            <div className="icon text-sd-green-500">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="code"
                className="svg-inline--fa fa-code"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
                style={{ width: "1em", height: "1em" }}
              >
                <path
                  fill="#38a169"
                  d="M399.1 1.1c-12.7-3.9-26.1 3.1-30 15.8l-144 464c-3.9 12.7 3.1 26.1 15.8 30s26.1-3.1 30-15.8l144-464c3.9-12.7-3.1-26.1-15.8-30zm71.4 118.5c-9.1 9.7-8.6 24.9 1.1 33.9L580.9 256 471.6 358.5c-9.7 9.1-10.2 24.3-1.1 33.9s24.3 10.2 33.9 1.1l128-120c4.8-4.5 7.6-10.9 7.6-17.5s-2.7-13-7.6-17.5l-128-120c-9.7-9.1-24.9-8.6-33.9 1.1zm-301 0c-9.1-9.7-24.3-10.2-33.9-1.1l-128 120C2.7 243 0 249.4 0 256s2.7 13 7.6 17.5l128 120c9.7 9.1 24.9 8.6 33.9-1.1s8.6-24.9-1.1-33.9L59.1 256 168.4 153.5c9.7-9.1 10.2-24.3 1.1-33.9z"
                ></path>
              </svg>
            </div>
            <div className="text">Code</div>
          </div>
          <div className="code-editor-container">
            {!selectedFile ? (
              <div className="m-3">Select a file</div>
            ) : (
              // <MonacoEditor
              //   height="100%"
              //   width="100%"
              //   theme={theme}
              //   defaultLanguage={"python"}
              //   defaultValue={"Select a file"}
              //   // value={answers[questions?.[currentQuestionIndex]?.id] || ""}
              //   // value={"Select a file"}
              //   options={{
              //     wordWrap: "on",
              //     minimap: { enabled: false },
              //   }}
              // />
              <MonacoEditor
                height="100%"
                width="100%"
                theme={theme}
                path={selectedFile.language}
                defaultLanguage={selectedFile.language}
                defaultValue={selectedFile.value}
                // value={answers[questions?.[currentQuestionIndex]?.id] || ""}
                value={selectedFile.value}
                onChange={(value) => {
                  handleAnswerChange(
                    questions?.[currentQuestionIndex]?.id || 0,
                    value || ""
                  );
                }}
                options={{
                  wordWrap: "on",
                  minimap: { enabled: false },
                }}
                onMount={handleEditorDidMount}
              />
            )}
          </div>
        </div>
      </>
    );
  }, [
    answers,
    currentQuestionIndex,
    file.language,
    theme,
    handleAnswerChange,
    questions,
    activeFileKey,
  ]);

  const currentQues = useMemo(
    () => questions[currentQuestionIndex]?.question,
    [questions, currentQuestionIndex]
  );

  const renderModal = useCallback(() => {
    if (!modalIsOpen || loading) return <></>;

    const header = (
      <div className="mockInterview-modal-header">
        <button
          className="modal-close"
          onClick={() => {
            forceSetMessages([]);
            dispatch(setinterviewMode("interview_exit"));
            dispatch(setInterviewCompleted(true));
            localStorage.removeItem("dev_questions");
            navigate("/interview/role/developer");
          }}
        >
          <i className="ri-close-line"></i>
        </button>
      </div>
    );

    const content = (
      <div className="row">
        <div className="col-12 order-md-1 order-2">
          {feedback?.map((item, index) => (
            <div className="cardBody" key={index + 100}>
              <h5 className="mx-3 my-3 ">Question:</h5>
              <hr />
              <p className="mx-3 my-3">{currentQues?.des}</p>
              <hr />
              <h5 className="mx-3 my-3 text-primary">Answer:</h5>
              <hr />
              <h6 className="mx-3 my-3 text-primary">{item?.answer}</h6>
              <hr />

              <h5 className="mx-3 my-3 text-primary">Feedback:</h5>
              <p className="mx-3 my-3">{item?.feedback}</p>
            </div>
          ))}
        </div>
      </div>
    );

    if (feedbackLoading) {
      return <LoaderOverlay>Loading...</LoaderOverlay>;
    }

    return (
      <Modal
        type="feedback"
        show={modalIsOpen}
        header={header}
        content={content}
      />
    );
  }, [modalIsOpen, messages, feedback, feedbackLoading]);

  const downloadFile = (label, value, type) => {
    const blob = new Blob([value], { type: "text/plain;charset=utf-8" });
    saveAs(blob, label);
  };

  const handleDownloadAll = () => {
    fileList.forEach((file) => downloadFile(file.label, file.value, file.type));

    setTimeout(() => {
      window.open("https://github.com/", "_blank"); // Replace with your desired URL
    }, 1000); // Adjust the delay as needed
  };

  useEffect(() => {
    fetchDir(".", (data) => {
      dispatch(loadDir({ path: ".", ...data }));
    });
    // socket.connect();
    // setTimeout(() => {
    //   saveFile({ path: selectedFile.label, content: selectedFile.value }, () => {
    //     alert("File saved successfully");
    //   });
    // }, 5000);
  }, []);

  const handleMenuClick = (e) => {
    setMenuVisible(false);
    // Handle menu item clicks here
  };

  const headerMenu = (
    <div className="flex justify-between">
      <div className="flex space-x-3 my-0 items-center">
        {/* <TimerComponent submitTest={submitTest} /> */}
        <button
          className="bg-red-600 text-white p-2 px-4 rounded-lg mb-3"
          onClick={() => {
            dispatch(setinterviewMode("interview_exit"));
            dispatch(setInterviewCompleted(true));
            navigate(-1);
          }}
        >
          Exit
        </button>
      </div>
      <Menu>
        <Menu.Item>Exit</Menu.Item>
      </Menu>
    </div>
  );

  const treeData = [
    {
      title: "src",
      key: "0-0",
      icon: <FolderOutlined />,
      children: [
        { title: "index.js", key: "0-0-0", icon: <FileOutlined /> },
        { title: "dashboard.js", key: "0-0-1", icon: <FileOutlined /> },
      ],
    },
    { title: "package.json", key: "0-1", icon: <FileOutlined /> },
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ padding: "0 16px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Button
            type="primary"
            danger
            icon={<></>}
            onClick={() => {
              dispatch(setinterviewMode("interview_exit"));
              dispatch(setInterviewCompleted(true));
              navigate(-1);
            }}
          >
            Exit
          </Button>
          <Space wrap>
            <Button
              icon={<GithubOutlined />}
              className="hidden md:inline-block"
            >
              Save & Export to GitHub
            </Button>
            <Button
              type="primary"
              icon={<PlayCircleOutlined />}
              className="hidden md:inline-block"
            >
              Run
            </Button>
            <Select
              defaultValue="javascript"
              style={{ width: 150 }}
              className="hidden lg:inline-block"
            >
              <Option value="javascript">JavaScript</Option>
              <Option value="python">Python</Option>
              <Option value="r">R</Option>
            </Select>
            <Select
              defaultValue="light"
              options={themes}
              onChange={(val) => setTheme(val)}
              placeholder="Select theme"
              style={{ width: 150 }}
              className="hidden lg:inline-block"
            >
              <Option value="light">Light</Option>
              <Option value="dark">Dark</Option>
              <Option value="system">System</Option>
            </Select>
            <Dropdown
              overlay={headerMenu}
              trigger={["click"]}
              visible={menuVisible}
              onVisibleChange={setMenuVisible}
              className="md:hidden"
            >
              <Button
                icon={<MenuOutlined />}
                onClick={(e) => e.preventDefault()}
              />
            </Dropdown>
          </Space>
        </div>
      </Header>
      <Layout>
        <Content
          style={{ padding: "24px" }}
          className="overflow-y-scroll h-screen"
        >
          <Space
            direction="vertical"
            size="large"
            style={{ display: "flex" }}
            className="mb-96"
          >
            <Card title="Question 1">
              {questions[currentQuestionIndex] instanceof Object ? (
                <>
                  <h1>{currentQues?.project}</h1>
                  <p>{currentQues?.des}</p>
                  <h2 className="mt-3 font-bold text-lg">Key Features</h2>
                  {currentQues?.keyFeatures?.map((e, i) => {
                    if (typeof e === "string") {
                      return (
                        <div className="mt-3">
                          <p>
                            {i + 1}. {e}
                          </p>
                        </div>
                      );
                    }

                    return (
                      <div className="mt-3">
                        <b>{e?.feature}</b>
                        <p>{e?.description}</p>
                      </div>
                    );
                  })}

                  <h2 className="mt-3 font-bold text-lg">
                    Technologies to Use
                  </h2>
                  {Object.keys(currentQues?.technologiesToUse).map((e) => {
                    return (
                      <div className="mt-3 flex space-x-1">
                        <b>{e}:</b>
                        <p>{currentQues?.technologiesToUse[e]}</p>
                      </div>
                    );
                  })}

                  <h2 className="mt-3 font-bold text-lg">
                    Steps to Build the Project
                  </h2>
                  {currentQues?.stepsToBuildTheProject?.map((e, i) => {
                    return (
                      <ul className="mt-3">
                        <l1>
                          {i + 1}. {e}
                        </l1>
                      </ul>
                    );
                  })}

                  {currentQues?.benefits ? (
                    <>
                      <h2 className="mt-3 font-bold text-lg">Benefits</h2>
                      {currentQues?.benefits?.map((e, i) => {
                        return (
                          <ul className="mt-3">
                            <l1>
                              {i + 1}. {e}
                            </l1>
                          </ul>
                        );
                      })}
                    </>
                  ) : null}

                  {currentQues?.howToShowcaseTheProject ? (
                    <>
                      <h2 className="mt-3 font-bold text-lg">
                        How to Showcase the Project
                      </h2>
                      {currentQues?.howToShowcaseTheProject?.map((e, i) => {
                        return (
                          <ul className="mt-3">
                            <l1>
                              {i + 1}. {e}
                            </l1>
                          </ul>
                        );
                      })}
                    </>
                  ) : null}
                </>
              ) : (
                <DSAQuestion question={questions[currentQuestionIndex]} />
              )}
              <ButtonContainer className="button-container">
                {currentQuestionIndex === questions.length - 1 && (
                  <Button className="next-button" onClick={submitTest}>
                    Submit
                  </Button>
                )}
              </ButtonContainer>
            </Card>
            <Card title="Code Editor" className="h-fit">
              <Layout>
                <Sider
                  width={200}
                  theme="light"
                  style={{ marginRight: "16px" }}
                >
                  <div className="p-2" style={{}}>
                    <h3 style={{ marginBottom: "8px" }}>Files</h3>
                    <Tree
                      treeData={treeData}
                      selectedKeys={selectedKeys}
                      onSelect={(selectedKeys) => setSelectedKeys(selectedKeys)}
                    />
                    <Button
                      icon={<PlusOutlined />}
                      style={{ marginTop: "16px", width: "100%" }}
                    >
                      New File
                    </Button>
                  </div>
                </Sider>
                <Content>
                  {monoEditor()}
                  <Divider />
                  <div>
                    <h4>Code Output</h4>
                    <pre
                      style={{
                        backgroundColor: "#f0f0f0",
                        padding: "16px",
                        borderRadius: "4px",
                        maxHeight: "200px",
                        overflowY: "auto",
                      }}
                    >
                      Code output will appear here
                    </pre>
                  </div>
                </Content>
              </Layout>
            </Card>
          </Space>
        </Content>
      </Layout>
    </Layout>
  );

  // return (
  //   <div className="ic-all-question flex flex-col">
  //     {renderModal()}

  //     <TestContainer className="w-full">
  //       {loading && <LoaderOverlay>Loading...</LoaderOverlay>}
  //       <div className="flex flex-col w-full">
  //         <QuestionContainer className="flex-1">
  //           <QuestionCard style={{ overflowY: "scroll", maxHeight: "82vh" }}>
  //             {questions[currentQuestionIndex] instanceof Object ? (
  //               <>
  //                 <h4>Question {currentQuestionIndex + 1}</h4>
  //                 {/* <p>{questions[currentQuestionIndex]?.question}</p> */}
  //                 <h1>{currentQues?.project}</h1>
  //                 <p>{currentQues?.des}</p>
  //                 <h2 className="mt-3 font-bold text-lg">Key Features</h2>
  //                 {currentQues?.keyFeatures?.map((e, i) => {
  //                   if (typeof e === "string") {
  //                     return (
  //                       <div className="mt-3">
  //                         <p>
  //                           {i + 1}. {e}
  //                         </p>
  //                       </div>
  //                     );
  //                   }

  //                   return (
  //                     <div className="mt-3">
  //                       <b>{e?.feature}</b>
  //                       <p>{e?.description}</p>
  //                     </div>
  //                   );
  //                 })}

  //                 <h2 className="mt-3 font-bold text-lg">
  //                   Technologies to Use
  //                 </h2>
  //                 {Object.keys(currentQues?.technologiesToUse).map((e) => {
  //                   return (
  //                     <div className="mt-3 flex space-x-1">
  //                       <b>{e}:</b>
  //                       <p>{currentQues?.technologiesToUse[e]}</p>
  //                     </div>
  //                   );
  //                 })}

  //                 <h2 className="mt-3 font-bold text-lg">
  //                   Steps to Build the Project
  //                 </h2>
  //                 {currentQues?.stepsToBuildTheProject?.map((e, i) => {
  //                   return (
  //                     <ul className="mt-3">
  //                       <l1>
  //                         {i + 1}. {e}
  //                       </l1>
  //                     </ul>
  //                   );
  //                 })}

  //                 {currentQues?.benefits ? (
  //                   <>
  //                     <h2 className="mt-3 font-bold text-lg">Benefits</h2>
  //                     {currentQues?.benefits?.map((e, i) => {
  //                       return (
  //                         <ul className="mt-3">
  //                           <l1>
  //                             {i + 1}. {e}
  //                           </l1>
  //                         </ul>
  //                       );
  //                     })}
  //                   </>
  //                 ) : null}

  //                 {currentQues?.howToShowcaseTheProject ? (
  //                   <>
  //                     <h2 className="mt-3 font-bold text-lg">
  //                       How to Showcase the Project
  //                     </h2>
  //                     {currentQues?.howToShowcaseTheProject?.map((e, i) => {
  //                       return (
  //                         <ul className="mt-3">
  //                           <l1>
  //                             {i + 1}. {e}
  //                           </l1>
  //                         </ul>
  //                       );
  //                     })}
  //                   </>
  //                 ) : null}
  //               </>
  //             ) : (
  //               <DSAQuestion question={questions[currentQuestionIndex]} />
  //             )}
  //             <ButtonContainer className="button-container">
  //               {/* <Button className="next-button" onClick={moveToNextQuestion}>
  //                 Next
  //               </Button> */}
  //               {currentQuestionIndex === questions.length - 1 && (
  //                 <Button className="next-button" onClick={submitTest}>
  //                   Submit
  //                 </Button>
  //               )}
  //             </ButtonContainer>
  //           </QuestionCard>
  //         </QuestionContainer>
  //         {/* <div className="bg-white mt-4 mr-4 rounded-md shadow-lg">
  //           <PreFormattedText>{responseData?.evaluation}</PreFormattedText>
  //           <RealTimeResponseBox
  //             answers={answers}
  //             currentQuestionIndex={currentQuestionIndex}
  //             questions={questions?.filter((e) => e)}
  //             techStack={techStack}
  //           />
  //         </div> */}
  //         <div className="flex flex-col w-1/2" style={{ height: "560px" }}>
  //           <CodeEditorContainer>
  //             <SelectContainer
  //               style={{ display: "flex", justifyContent: "flex-end" }}
  //             >
  //               {currentQuestionIndex === questions.length - 1 && (
  //                 <Button
  //                   className="rounded-md mr-2 p-2"
  //                   onClick={handleDownloadAll}
  //                 >
  //                   Save & Export to github
  //                 </Button>
  //               )}
  //               <Button
  //                 className="rounded-md mr-2 p-2 px-3"
  //                 onClick={() =>
  //                   dispatch(
  //                     executeCode({
  //                       filename: selectedFile.label,
  //                       code: selectedFile.value,
  //                       language: selectedFile.language,
  //                     })
  //                   )
  //                 }
  //               >
  //                 Run
  //               </Button>
  //               <div className="mr-3">
  //                 <Select
  //                   options={files?.map((e) => ({
  //                     ...e,
  //                     label: e.language,
  //                     value: e.sign,
  //                   }))}
  //                   onChange={(e) => setSelectedFileType(e.value)}
  //                   styles={customStyles}
  //                   placeholder="Select Language"
  //                   // value={selectedFileType}
  //                 />
  //               </div>
  //               <div>
  //                 <Select
  //                   options={themes}
  //                   onChange={handleChangeTheme}
  //                   styles={customStyles}
  //                   placeholder="Select Theme"
  //                 />
  //               </div>
  //             </SelectContainer>

  //             <div className="flex gap-2">
  //               <div className="w-60">
  //                 <FileSystem
  //                   files={state.fileTree.childFiles}
  //                   dirs={state.fileTree.childDirs}
  //                 />
  //               </div>
  //               <div
  //                 className="flex flex-col w-3/4 min-w-[500px]"
  //                 style={{ flex: 1, height: "560px" }}
  //               >
  //                 {state.loaders.executeCodeLoader && (
  //                   <div className="flex justify-center mt-10">
  //                     <img
  //                       className="w-32"
  //                       src="https://cdn.dribbble.com/users/3742211/screenshots/9195657/media/6796a544d6f9ef1293d8d8d9e60d38d5.gif"
  //                       alt="loader"
  //                     />
  //                   </div>
  //                 )}
  //                 {state.executeCodeResult?.output &&
  //                   !state.loaders.executeCodeLoader && (
  //                     <div className=" m-1 bg-white shadow-md p-2">
  //                       <h1 className="text-lg">Output:</h1>
  //                       <div>{state.executeCodeResult?.output}</div>
  //                     </div>
  //                   )}
  //               </div>
  //             </div>
  //           </CodeEditorContainer>
  //         </div>
  //       </div>
  //     </TestContainer>
  //   </div>
  // );
});
/*Use memo to not re render time component each time */
const TimerComponent = memo(({ submitTest }) => {
  const [remainingTime, setRemainingTime] = useState(600); // 10 minutes
  const timerRef = useRef(null);
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = () => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          timerRef.current = null;
          submitTest();
          return 0;
        }
        return prevTime - 1;
      });
    };
  });

  useEffect(() => {
    if (!timerRef.current) {
      timerRef.current = setInterval(() => savedCallback.current(), 1000);
    }

    return () => clearInterval(timerRef.current);
  }, []);

  return (
    <TimerContainer>
      <TimerIcon>⏰</TimerIcon>
      <Timer>Time Remaining: {formatTime(remainingTime)}</Timer>
    </TimerContainer>
  );
});

export default Interview;
