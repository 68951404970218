import React, { useRef, useState } from "react";
import { LuExternalLink } from "react-icons/lu";
import { MdVerified } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { SlCalender } from "react-icons/sl";
import { CgAttachment } from "react-icons/cg";
import { FaArrowLeftLong } from "react-icons/fa6";
import ReactModal from "react-modal";
import { Select } from "antd";
import { useSelector } from "react-redux";
import { error } from "../../Utilities/toast";
import dayjs from "dayjs";

const NexaIqSideBar = ({ isOpen, toggleSidebar }) => {
  const { getSingleJobData } = useSelector((state) => state.nexaIq);
  const fileInput = useRef(null);
  const [isOpenModal, setIsOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [uploadedFile, setUploadedFile] = useState(null);
  const questions = [
    "How do blockchain technologies enhance security in digital transactions?",
    "What is quantum computing?",
    "How does 3D printing work?",
    "What is the purpose of a VPN?",
    "What is augmented reality (AR)?",
  ];

  const handleSubmit = () => {
    if (activeStep === 1) {
      setActiveStep(2);
    } else {
      toggleModal();
      setActiveStep(1);
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpenModal);
  };
  const getFile = (e) => {
    const file = e.target.files[0];
    if (file.type === "application/pdf") {
      if (!file) return;
      const fileName =
        file.name.length >= 50
          ? file.name.substring(0, 51) + "..." + file.name.split(".")[1]
          : file.name;

      if (file && file.size > 2.5 * 1024 * 1024) {
        error("File size exceeds 2.5MB, please select a smaller file.");
        return;
      }

      setUploadedFile(file);
    } else {
      error("Only PDF file Supported");
    }
  };
  return (
    <div
      className={`z-[999] fixed top-0 right-0 bg-white shadow-lg overflow-auto transition-transform duration-300 w-[95%] lg:w-[70%] xl:w-[65%] ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="py-6 border flex h-[100vh] overflow-y-scroll justify-center">
        <div className="w-[65%] lg:w-[65%] xl:w-[70%] h-[100%]  flex flex-col">
          <div className="px-6 w-full border-r-2">
            <button onClick={toggleSidebar}>
              <FaArrowLeftLong size={20} color="black" />
            </button>
          </div>
          <div className="h-auto w-full border-b-2 px-6 pb-8 border-r-2">
            <h2 className="text-2xl mt-8 mb-2">{getSingleJobData?.title}</h2>
            <div className="flex justify-left item-center gap-5 mt-4">
              <span className="text-sm font-light">
                {getSingleJobData?.employment_type ||
                  getSingleJobData?.tonality}
              </span>
              <h2 className="flex gap-1 items-center text-sm font-light">
                <CiLocationOn size={20} color="black" />
                {getSingleJobData?.location}
              </h2>
            </div>
          </div>
          <div
            className={`h-auto w-full ${
              getSingleJobData?.type !== "google" ? "border-b-2" : ""
            } px-6 py-6 border-r-2`}
          >
            <p className="text-md">
              {getSingleJobData?.description ||
                getSingleJobData?.job_description}
            </p>
          </div>
          {getSingleJobData?.type !== "google" && (
            <>
              <div className="h-auto flex justify-center items-start w-full border-b-2 px-6 py-6 border-r-2">
                <div className="w-[50%] flex justify-start item-center">
                  <div className="flex justify-center item-center gap-2">
                    <div className="">
                      <SlCalender size={24} />
                    </div>
                    <div className="flex justify-center item-center flex-col gap-1">
                      <span className="text-md font-bold">
                        Prescreening Deadline
                      </span>
                      <span className="text-md font-medium">
                        {dayjs(getSingleJobData?.prescreening_deadline).format(
                          "DD MMM YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-[50%] flex justify-start item-center">
                  <div className="flex justify-center item-center gap-2">
                    <div className="">
                      <SlCalender size={24} />
                    </div>
                    <div className="flex justify-center item-center flex-col gap-1">
                      <span className="text-md font-bold">
                        Interview Deadline
                      </span>
                      <span className="text-md font-medium">
                        {dayjs(getSingleJobData?.interview_deadline).format(
                          "DD MMM YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="h-auto w-full px-6 py-6 border-r-2">
                <h2 className="text-xl mb-3">Skills and Expertise</h2>
                <div className="flex justify-start items-center">
                  {getSingleJobData?.skills &&
                    getSingleJobData.skills.split(",").map((skill, index) => (
                      <div
                        key={index}
                        className="chip"
                        style={{
                          margin: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {skill.trim()}
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="w-[35%] lg:w-[35%] xl:w-[30%] h-[100%] flex flex-col px-3">
          <h2 className="flex gap-2 items-center -mt-2">
            <LuExternalLink size={20} color="black" />
            Open job in new window
          </h2>
          <div className="flex justify-center w-full flex-col gap-3 mt-10">
            {getSingleJobData?.type !== "google" && (
              <div
                className="flex w-full justify-center cursor-pointer flex-row items-center rounded-[8px] border-[2px] border-[rgba(255,255,255,0.3)] bg-gradient-auto-apply py-[8px] px-[46px] font-semibold text-white opacity-50"
                onClick={toggleModal}
              >
                <svg
                  width="16"
                  height="16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-[8px]"
                >
                  <path
                    d="M7.167 1.332a.5.5 0 0 0-.439.26L3.062 8.257a.5.5 0 0 0 .438.74L6.8 8.4l-3.096 5.576a.5.5 0 0 0 .816.544l8.334-8.333a.5.5 0 0 0-.354-.854l-2.9.309 2.665-3.557a.5.5 0 0 0-.432-.752H7.167Z"
                    fill="#F7AB38"
                  ></path>
                </svg>
                <div>Apply now</div>
              </div>
            )}
            {getSingleJobData?.type == "google" &&
              getSingleJobData?.apply_links?.map((data, index) => (
                <div
                  className="flex w-full justify-center cursor-pointer flex-row items-center rounded-[8px] border-[2px] border-[rgba(255,255,255,0.3)] bg-gradient-auto-apply py-[8px] px-[26px] font-semibold text-white opacity-50 text-sm"
                  onClick={() => window.open(data?.url, "_blank")}
                >
                  <div>{data?.text}</div>
                </div>
              ))}
          </div>
          <div className="w-full mb-8">
            <h2 className="text-xl mt-8 mb-2">About the Companey</h2>
            <h2 className="flex gap-1 items-center mt-1 text-sm">
              <MdVerified size={18} color="#7c96fd" />
              {getSingleJobData?.company ||
                getSingleJobData?.organization?.name}
            </h2>
            {getSingleJobData?.organization && (
              <>
                <h2 className="flex gap-1 items-center mt-1 text-sm">
                  <MdVerified size={18} color="#7c96fd" />
                  {getSingleJobData?.company ||
                    getSingleJobData?.organization?.address}
                </h2>
                <h2 className="flex gap-1 items-center mt-1 text-sm">
                  <MdVerified size={18} color="#7c96fd" />
                  {getSingleJobData?.company ||
                    getSingleJobData?.organization?.phone}
                </h2>
              </>
            )}
          </div>
          {/* <div className="w-full">
            <h2 className="text-xl mt-8 mb-2">Job link</h2>
            <input
              type="text"
              id="resumeName"
              className="form-control"
              disabled
              placeholder="https://www.upwork.com/jobs/~021839217863126417414"
            />
            <h2 className="flex gap-1 items-center mt-2 text-sm">Copy link</h2>
          </div> */}
        </div>
      </div>
      <ReactModal
        isOpen={isOpenModal}
        onRequestClose={toggleModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px 40px",
            maxWidth: "750px",
            width: "95%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            zIndex: 1001,
            maxHeight: "90vh",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          },
        }}
      >
        <h2 className="text-xl font-bold mt-2">Apply Job</h2>
        {/* <div className="modal-body-iq flex flex-col gap-4 mt-2">
          <label className="modal-label">
            <span className="text-xl">
              How do blockchain technologies enhance security in digital
              transactions?
            </span>
            <textarea
              rows="4"
              className="modal-input-Answers w-full h-20"
              placeholder="Answer here..."
            />
          </label>
          <label className="modal-label">
            <span className="text-xl">What is quantum computing?</span>
            <textarea
              rows="4"
              className="modal-input-Answers w-full h-20"
              placeholder="Answer here..."
            />
          </label>
          <label className="modal-label">
            <span className="text-xl">How does 3D printing work?</span>
            <textarea
              rows="4"
              className="modal-input-Answers w-full h-20"
              placeholder="Answer here..."
            />
          </label>
          <label className="modal-label">
            <span className="text-xl">What is the purpose of a VPN?</span>
            <textarea
              rows="4"
              className="modal-input-Answers w-full h-20"
              placeholder="Answer here..."
            />
          </label>
          <label className="modal-label">
            <span className="text-xl">What is augmented reality (AR)?</span>
            <textarea
              rows="4"
              className="modal-input-Answers w-full h-20"
              placeholder="Answer here..."
            />
          </label>
        </div> */}
        <div className="modal-body-iq flex flex-col gap-4 mt-2">
          {activeStep == 1 ? (
            questions.map((question, index) => (
              <label key={index} className="modal-label">
                <span className="text-xl">{question}</span>
                <textarea
                  rows="4"
                  className="modal-input-Answers w-full h-20"
                  placeholder="Answer here..."
                />
              </label>
            ))
          ) : (
            <label className="modal-label modalNexaIQ">
              <span className="text-xl">Upload Your Resume For Apply</span>
              <div className="pt-4 group-open:animate-fadeIn">
                <form action="#">
                  <input
                    type="file"
                    name="file"
                    ref={fileInput}
                    accept=".pdf"
                    hidden
                    onChange={(e) => getFile(e)}
                  />
                  <div
                    className="ic-file-upload"
                    onClick={() => {
                      fileInput.current.click();
                    }}
                  >
                    <div className="ic-icon">
                      <img
                        src="/images/document-upload.png"
                        alt=""
                        style={{ width: "auto" }}
                      />
                    </div>
                    <p className="support-text">
                      <span className="primary">Click to Upload </span>
                      or drag and drop
                    </p>
                    <span className="ic-file-size">
                      {" "}
                      (Max. File size: 2.5 MB)
                    </span>
                  </div>
                </form>

                {/* {uploadedFile && (
                  <div className="ic-loading-area">
                    <ul>
                      <li>
                        <div className="file-name">
                          <div className="file-icon">
                            <img src="/images/document-text-area.png" alt="" />
                          </div>
                          <div className="file-size">
                            <div>
                              <p>{uploadedFile.name}</p>
                              {uploadedFile.size && (
                                <span>{uploadedFile.size}</span>
                              )}
                            </div>
                            {uploadedFile.loading === 100 && (
                              <i className="ri-checkbox-circle-fill"></i>
                            )}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                )} */}
              </div>
            </label>
          )}
        </div>
        <div className="modal-footer">
          <button
            onClick={() => {
              if (activeStep == 1) {
                toggleModal();
              } else {
                setActiveStep(1);
              }
            }}
            className="modal-button cancel-button"
          >
            {activeStep == 1 ? "Cancel" : "Back"}
          </button>
          <button className="modal-button save-button" onClick={handleSubmit}>
            {activeStep == 1 ? "Next" : "Submit"}
          </button>
        </div>
      </ReactModal>
    </div>
  );
};

export default NexaIqSideBar;
