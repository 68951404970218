import React, { useEffect, useState } from "react";
import {
  FaMicrophoneAlt,
  FaMicrophoneAltSlash,
  FaPhone,
  FaVideo,
  FaVideoSlash,
} from "react-icons/fa";
import Dictaphone from "./Dictaphone";
import Loader from "../../../Components/main-loader";
import { stopMachineSpeaks } from "./MachineSpeaks";

const MeetScreen = ({
  videoStream,
  handleMute,
  handleCamera,
  handleEndCall,
  muted,
  jobDescription,
  selectedResumeID,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className="mt-32 p-5">
      <div className=" p-4  my-16 bg-gray-800 rounded-lg relative">
        <div className=" flex w-full h-[80vh] justify-between gap-10 items-center">
          <div className="w-3/4 h-[90%] rounded-lg overflow-hidden relative">
            {loading && <Loader />}
            <video
              autoPlay
              playsInline
              ref={(video) =>
                video && videoStream && (video.srcObject = videoStream)
              }
              className="w-full h-full object-cover"
              id="video_stream"
              style={{ transform: "scaleX(-1)" }}
            ></video>
            <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 flex space-x-4">
              <button
                onClick={handleMute}
                className="flex items-center justify-center w-12 h-12 bg-gray-900 text-white rounded-full"
              >
                {muted ? <FaMicrophoneAlt /> : <FaMicrophoneAltSlash />}
              </button>
              <button
                onClick={() => {
                  handleEndCall();
                  stopMachineSpeaks();
                }}
                className="flex items-center justify-center w-14 h-14 bg-red-500 rounded-full text-white shadow-lg"
              >
                <FaPhone />
              </button>
              <button
                onClick={handleCamera}
                className="flex items-center justify-center w-12 h-12 bg-gray-900 text-white rounded-full"
              >
                {videoStream === null ? <FaVideoSlash /> : <FaVideo />}
              </button>
            </div>
          </div>
          <div className="w-1/4 h-[90%] flex justify-center items-center ">
            <div className="rounded-xl overflow- border border-white-700 p-5 relative">
              <Dictaphone
                jobDescription={jobDescription}
                muted={muted}
                selectedResumeID={selectedResumeID}
                handleEndCall={handleEndCall}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetScreen;
