import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import { error, success } from "../Utilities/toast";
// import { addSkill } from "../features/Skills/SkillsSlice";

const resumeRepo = RepositoryFactory.get("resume");
const jobRepo = RepositoryFactory.get("job");

// resume

export const addNewResume = createAsyncThunk(
  "resume/addNewResume",
  async ({ createResume, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.createResume(createResume);
      if (data?.status_code === 200) {
        onSuccess(data?.detail, data?.data);
        return data.data;
      } else {
        error("Error occuring due to exusted quota limit");
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      onError(err?.response?.data);
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);
export const uploadExistingResume = createAsyncThunk(
  "resume/uploadExistingResume",
  async ({ payload, onSuccess }, { dispatch, rejectWithValue }) => {
    try {
      const response = await resumeRepo.uploadExisting(payload);
      await dispatch(getResume({ onSuccess: () => {} }));
      success(response?.data?.message);
      onSuccess();
      return response?.data;
    } catch (err) {
      error(err?.data?.detail || "There was an error");
      return rejectWithValue(err);
    }
  }
);
export const updateResume = createAsyncThunk(
  "resume/updateResume",
  async (
    { createResume, resumeId, onSuccess, onError },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await resumeRepo.updateResume(resumeId, createResume);
      if (data?.status_code === 200) {
        onSuccess();
        return data.data;
      } else {
        onError(data?.detail);
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      onError(err?.response?.data);
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);
export const upgradeResume = createAsyncThunk(
  "resume/upgradeResume",
  async ({ resumeId, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.upgradeResume(resumeId);
      if (data?.status_code === 201) {
        console.log(data.data, "data.data");
        onSuccess({ status: true, id: data.data.id });
        success("Version upgraded successfully");
        return data.data;
      }
    } catch (err) {
      error("Failed to copy resume due to exusted quota limit");
      onSuccess({ status: false, id: "" });
      return rejectWithValue(err.message);
    }
  }
);
export const getResume = createAsyncThunk(
  "resume/getResume",
  async ({ onSuccess = () => {} }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.getAllResume();
      onSuccess(data?.detail);
      return data.data;
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

export const getSingleResume = createAsyncThunk(
  "resume/getSingleResume",
  async ({ resumeId, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.getSingleResume(resumeId);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);
export const generateAIResume = createAsyncThunk(
  "resume/AIResume",
  async ({ resumeId }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.generateResume(resumeId);
      console.log(data, "generateAIResume");
      return data?.aiResume;
    } catch (err) {
      console.error("Error fetching training analytics", err);
      error("Failed to generate resume");
      return rejectWithValue(err.message);
    }
  }
);

/////Profile crud

export const addResumeProfile = createAsyncThunk(
  "resume/addResumeProfile",
  async ({ userInfo, id, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.addContactInfo(userInfo, id);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);
export const udpateResumeProfile = createAsyncThunk(
  "resume/udpateResumeProfile",
  async ({ userInfo, onSuccess }, { rejectWithValue }) => {
    console.log(userInfo, "userInfouserInfo");
    try {
      const { data } = await resumeRepo.updateContactInfo(userInfo);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getResumeProfile = createAsyncThunk(
  "resume/getResumeProfile",
  async ({ resumeId, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.getContactInfo(resumeId);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        console.log(data, "getResumeProfile");
        return data.data;
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err);
    }
  }
);

/////BLURB crud

export const addResumeBlurb = createAsyncThunk(
  "resume/addResumeBlurb",
  async ({ payload, id, onSuccess }, { rejectWithValue }) => {
    console.log(payload);
    try {
      const { data } = await resumeRepo.addBlurb(payload, id);
      if (data?.status_code === 201) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);
export const udpateResumeBlurb = createAsyncThunk(
  "resume/udpateResumeBlurb",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    console.log(payload, "userInfouserInfo");
    try {
      const { data } = await resumeRepo.updateBlurb(payload);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

export const getResumeBlurb = createAsyncThunk(
  "resume/getResumeBlurb",
  async ({ resumeId, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.getBlurb(resumeId);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

export const deleteResumeBlurb = createAsyncThunk(
  "resume/deleteResumeBlurb",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    console.log(payload);
    try {
      const { data } = await resumeRepo.deleteBlurb(payload);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

/////Education crud

export const addResumeEducation = createAsyncThunk(
  "resume/addResumeEducation",
  async ({ payload, id, onSuccess }, { rejectWithValue }) => {
    console.log(payload);
    try {
      const { data } = await resumeRepo.addEducation(payload, id);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);
export const udpateResumeEducation = createAsyncThunk(
  "resume/udpateResumeEducation",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    console.log(payload, "userInfouserInfo");
    try {
      const { data } = await resumeRepo.updateEducation(payload);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

export const getResumeEducation = createAsyncThunk(
  "resume/getResumeEducation",
  async ({ resumeId, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.getEducation(resumeId);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

export const deleteResumeEducation = createAsyncThunk(
  "resume/deleteResumeEducation",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    console.log(payload);
    try {
      const { data } = await resumeRepo.deleteEducation(payload);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

////certificate crud

export const addResumeCertificate = createAsyncThunk(
  "resume/addResumeCertificate",
  async ({ payload, id, onSuccess }, { rejectWithValue }) => {
    console.log(payload);
    try {
      const { data } = await resumeRepo.addCertification(payload, id);
      if (data?.status_code === 201) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);
export const udpateResumeCertificate = createAsyncThunk(
  "resume/udpateResumeCertificate",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    console.log(payload, "userInfouserInfo");
    try {
      const { data } = await resumeRepo.updateCertification(payload);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

export const getResumeCertificate = createAsyncThunk(
  "resume/getResumeCertificate",
  async ({ resumeId, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.getCertification(resumeId);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

export const deleteResumeCertificate = createAsyncThunk(
  "resume/deleteResumeCertificate",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    console.log(payload);
    try {
      const { data } = await resumeRepo.deleteCertification(payload);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

/////Projects crud

export const addResumeProjects = createAsyncThunk(
  "resume/addResumeProjects",
  async ({ payload, id, onSuccess }, { rejectWithValue }) => {
    console.log(payload);
    try {
      const { data } = await resumeRepo.addProject(payload, id);
      if (data?.status_code === 201) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);
export const udpateResumeProjects = createAsyncThunk(
  "resume/udpateResumeProjects",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    console.log(payload, "userInfouserInfo");
    try {
      const { data } = await resumeRepo.updateProject(payload);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

export const getResumeProjects = createAsyncThunk(
  "resume/getResumeProjects",
  async ({ resumeId, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.getProject(resumeId);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

export const deleteResumeProjects = createAsyncThunk(
  "resume/deleteResumeProjects",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    console.log(payload);
    try {
      const { data } = await resumeRepo.deleteProject(payload);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

/////work Experience Crud

export const addResumeCompany = createAsyncThunk(
  "resume/addResumeCompany",
  async ({ companies, id, onSuccess }, { rejectWithValue }) => {
    // console.log(payload);
    try {
      const { data } = await resumeRepo.addExperience(companies, id);
      if (data?.status_code === 201) {
        onSuccess(data?.detail, data.data.id);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);
export const addResumeRoles = createAsyncThunk(
  "resume/addResumeRoles",
  async ({ role, roleId, onSuccess }, { rejectWithValue }) => {
    // console.log(payload);
    try {
      const { data } = await resumeRepo.addRoles(role, roleId);
      if (data?.status_code === 201) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

export const udpateResumeCompaney = createAsyncThunk(
  "resume/udpateResumeCompaney",
  async ({ companies, roleId, onSuccess }, { rejectWithValue }) => {
    console.log(companies, roleId, "userInfouserInfo");
    try {
      const { data } = await resumeRepo.udpateResumeCompaney(companies, roleId);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

export const udpateResumeRoles = createAsyncThunk(
  "resume/udpateResumeRoles",
  async ({ roles, roleId, onSuccess }, { rejectWithValue }) => {
    console.log(roles, roleId, "userInfouserInfo");
    try {
      const { data } = await resumeRepo.udpateResumeRoles(roles, roleId);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

// export const getResumeExperience = createAsyncThunk(
//   "resume/getResumeExperience",
//   async ({ resumeId, onSuccess }, { rejectWithValue }) => {
//     try {
//       const { data } = await resumeRepo.getRoles(resumeId);
//       if (data?.status_code === 200) {
//         onSuccess(data?.detail);
//         return data.data;
//       } else {
//         throw new Error("Failed to fetch training analytics");
//       }
//     } catch (err) {
//       console.error("Error fetching training analytics", err);
//       return rejectWithValue(err.message);
//     }
//   }
// );

export const deleteResumeCompaney = createAsyncThunk(
  "resume/deleteResumeCompaney",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    console.log(payload);
    try {
      const { data } = await resumeRepo.deleteCompaney(payload);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

export const deleteResumeRole = createAsyncThunk(
  "resume/deleteResumeRole",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    console.log(payload);
    try {
      const { data } = await resumeRepo.deleteRole(payload);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

////Category

export const addResumeCategory = createAsyncThunk(
  "resume/addCategory",
  async ({ ID, payload, onSuccess }, { rejectWithValue }) => {
    console.log(ID, payload, "addResumeCategory>>>>>>>>>>>>>>>>");
    try {
      const { data } = await resumeRepo.postCategory(ID, payload);
      if (data?.status_code === 201) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to add category");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error adding category", err);
      return rejectWithValue(err.message);
    }
  }
);
export const updateResumeCategory = createAsyncThunk(
  "resume/updateCategory",
  async ({ ID, payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.putCategory(ID, payload);
      console.log(data, "updateResumeCategory");
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to update category");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error updating category", err);
      return rejectWithValue(err.message);
    }
  }
);
export const deleteResumeCategory = createAsyncThunk(
  "resume/deleteCategory",
  async ({ ID, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.delCategory(ID);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return ID;
      } else {
        throw new Error("Failed to delete category");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error deleting category", err);
      return rejectWithValue(err.message);
    }
  }
);
export const getResumeCategory = createAsyncThunk(
  "resume/getCategory",
  async ({ ID, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.getCategory(ID);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data?.data;
      } else {
        throw new Error("Failed to get categories");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error fetching categories", err);
      return rejectWithValue(err.message);
    }
  }
);

////SKills
export const addResumeSkill = createAsyncThunk(
  "resume/addSkill",
  async ({ ID, payload, onSuccess }, { rejectWithValue }) => {
    console.log(payload, "addResumeSkill>>>>>>>>>>>>>>>>");
    try {
      const { data } = await resumeRepo.postSkill(ID, payload);
      if (data?.status_code === 201) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to add skill");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error adding skill", err);
      return rejectWithValue(err.message);
    }
  }
);
export const updateResumeSkill = createAsyncThunk(
  "resume/updateSkill",
  async ({ ID, payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.putSkill(ID, payload);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to update skill");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error updating skill", err);
      return rejectWithValue(err.message);
    }
  }
);
export const deleteResumeSkill = createAsyncThunk(
  "resume/deleteSkill",
  async ({ ID, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.delSkill(ID);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return ID;
      } else {
        throw new Error("Failed to delete skill");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error deleting skill", err);
      return rejectWithValue(err.message);
    }
  }
);
export const getSkills = createAsyncThunk(
  "resume/getAllSKills",
  async ({ ID, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.getSkill(ID);
      console.log(data, "allskills>>>>>>>>>>>>>>>>>>>");
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        const originalArray = data.data;
        const transformedArray = originalArray.map((item) => ({
          Id: item.category?.id,
          categoryName: item.category?.name,
          skills: [
            {
              ID: item?.id,
              name: item?.name,
              resumeID: item?.resume_id,
              categoryID: item?.category_id,
            },
          ],
        }));
        return transformedArray;
      } else {
        throw new Error("Failed to fetch skills");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error fetching skills", err);
      return rejectWithValue(err.message);
    }
  }
);
////Publications
export const addResumePublication = createAsyncThunk(
  "resume/addPublication",
  async ({ ID, payload, onSuccess, onError }, { rejectWithValue }) => {
    console.log(ID, payload, "addResumeCategory>>>>>>>>>>>>>>>>");
    try {
      const { data } = await resumeRepo.postPublication(ID, payload);
      if (data?.status_code === 201) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to add Publication");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error adding Publication", err);
      return rejectWithValue(err.message);
    }
  }
);
export const getResumePublication = createAsyncThunk(
  "resume/getResumePublication",
  async ({ ID, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.getPublications(ID);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to fetch Publications");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error fetching Publications", err);
      return rejectWithValue(err.message);
    }
  }
);
export const updateResumePublication = createAsyncThunk(
  "resume/updatePublication",
  async ({ ID, payload, onSuccess }, { rejectWithValue }) => {
    console.log(ID, payload, "updateResumePublications>>>>>>>>>>>>>>>>");
    try {
      const { data } = await resumeRepo.putPublication(ID, payload);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to update Publication");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error updating Publication", err);
      return rejectWithValue(err.message);
    }
  }
);
export const deleteResumePublication = createAsyncThunk(
  "resume/deletePublication",
  async ({ ID, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.delPublication(ID);
      console.log(data, "delpublication>>>>>>>>>>>>>>>>");
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        error(error?.response.data.detail);
        throw new Error("Failed to delete Publication");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error deleting Publication", err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

///Job step 2 CRUD
export const addResumeJob = createAsyncThunk(
  "resume/addJob",
  async ({ jobID, payload }, { rejectWithValue }) => {
    // console.log(jobID, payload, "addResumeJOB>>>>>>>>>>>>>>>>");
    try {
      const { data } = await jobRepo.Add_JOB(jobID, payload);
      if (data?.status_code === 201) {
        return data;
      } else {
        throw new Error("Failed to add job");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error adding job", err);
      return rejectWithValue(err.message);
    }
  }
);
export const getResumeJob = createAsyncThunk(
  "resume/getJob",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await jobRepo.GET_JOB(id);
      if (data?.status_code === 200) {
        return data.data;
      } else if (data?.status_code === 404) {
        return {};
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error fetching job", err);
      return rejectWithValue(err.message);
    }
  }
);
export const udpateResumeJob = createAsyncThunk(
  "resume/upateJob",
  async ({ jobID, payload }, { rejectWithValue }) => {
    try {
      const { data } = await jobRepo.UPDATE_JOB(jobID, payload);
      if (data?.status_code === 200) {
        return data;
      } else {
        throw new Error("Failed to update job");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error updating job", err);
      return rejectWithValue(err.message);
    }
  }
);
////     Interest Actions
export const getResumeInterests = createAsyncThunk(
  "resume/getInterests",
  async ({ resumeId, onSuccess }, { rejectWithValue }) => {
    console.log(resumeId, "getResumeInterest>>>>>>>>>>>>>>>>");
    try {
      const { data } = await resumeRepo.getInterests(resumeId);
      if (data?.status_code === 201) {
        onSuccess(data?.detail);
        return data.data[0];
      } else {
        throw new Error("Failed to get interests");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error fetching interests", err);
      return rejectWithValue(err.message);
    }
  }
);
export const addResumeInterests = createAsyncThunk(
  "resume/addInterests",
  async ({ resumeId, payload, onSuccess }, { rejectWithValue }) => {
    console.log(resumeId, payload, "addResumeInterests>>>>>>>>>>>>>>>>");
    try {
      const { data } = await resumeRepo.addInterests(resumeId, payload);
      if (data?.status_code === 201) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to add Interest");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error adding Interest", err);
      return rejectWithValue(err.message);
    }
  }
);
export const updateResumeInterests = createAsyncThunk(
  "resume/updateInterests",
  async ({ interestId, payload, onSuccess }, { rejectWithValue }) => {
    console.log(interestId, payload, "addResumeInterests>>>>>>>>>>>>>>>>");
    try {
      const { data } = await resumeRepo.updateInterests(interestId, payload);
      if (data?.status_code === 201) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to update Interest");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error updating Interest", err);
      return rejectWithValue(err.message);
    }
  }
);
// export const deleteResumeInterests = createAsyncThunk(
//   "resume/deleteInterests",
//   async ({ interestId, onSuccess }, { rejectWithValue }) => {
//     try {
//       const { data } = await resumeRepo.deleteInterests(interestId);
//       console.log(data, "delinterest>>>>>>>>>>>>>>>>");
//       if (data?.status_code === 200) {
//         onSuccess(data?.detail);
//         return data.data;
//       } else {
//         error(err.response.data.detail);
//         throw new Error("Failed to delete Interest");
//       }
//     } catch (err) {
//       error(err.response.data.detail);
//       console.error("Error deleting Interest", err.response.data.detail);
//       return rejectWithValue(err.message);
//     }
//   }
// );
////      Languages Actions
export const getResumeLanguages = createAsyncThunk(
  "resume/getLanguages",
  async ({ resumeId, onSuccess }, { rejectWithValue }) => {
    console.log(resumeId, "getResumeLanguages>>>>>>>>>>>>>>>>");
    try {
      const { data } = await resumeRepo.getLanguages(resumeId);
      if (data?.status_code === 201) {
        onSuccess(data?.detail);
        return data.data[0];
      } else {
        throw new Error("Failed to get Languages");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error fetching Languages", err);
      return rejectWithValue(err.message);
    }
  }
);
export const addResumeLanguages = createAsyncThunk(
  "resume/addLanguages",
  async ({ resumeId, payload, onSuccess }, { rejectWithValue }) => {
    console.log(resumeId, payload, "addResumeLanguages>>>>>>>>>>>>>>>>");
    try {
      const { data } = await resumeRepo.addLanguages(resumeId, payload);
      if (data?.status_code === 201) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to add Languages");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error adding Languages", err);
      return rejectWithValue(err.message);
    }
  }
);
export const updateResumeLanguages = createAsyncThunk(
  "resume/updateLanguages",
  async ({ languageId, payload, onSuccess }, { rejectWithValue }) => {
    console.log(languageId, payload, "addResumeLanguages>>>>>>>>>>>>>>>>");
    try {
      const { data } = await resumeRepo.updateLanguages(languageId, payload);
      if (data?.status_code === 201) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to update Languages");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error updating Languages", err);
      return rejectWithValue(err.message);
    }
  }
);
// export const deleteResumeLanguages = createAsyncThunk(
//   "resume/deleteLanguages",
//   async ({ languageId, onSuccess }, { rejectWithValue }) => {
//     try {
//       const { data } = await resumeRepo.deleteLanguages(languageId);
//       console.log(data, "delLanguages>>>>>>>>>>>>>>>>");
//       if (data?.status_code === 200) {
//         onSuccess(data?.detail);
//         return data.data;
//       } else {
//         error(err.response.data.detail);
//         throw new Error("Failed to delete Languages");
//       }
//     } catch (err) {
//       error(err.response.data.detail);
//       console.error("Error deleting Languages", err.response.data.detail);
//       return rejectWithValue(err.message);
//     }
//   }
// );
////    Soft Skills
export const getResumeSoftSkills = createAsyncThunk(
  "resume/getSoftSkills",
  async ({ resumeId, onSuccess }, { rejectWithValue }) => {
    console.log(resumeId, "getSoftSkills>>>>>>>>>>>>>>>>");
    try {
      const { data } = await resumeRepo.getSoftSkills(resumeId);
      if (data?.status_code === 201) {
        onSuccess(data?.detail);
        return data.data[0];
      } else {
        throw new Error("Failed to get Soft Skills");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error fetching Soft Skills", err);
      return rejectWithValue(err.message);
    }
  }
);
export const updateResumeSoftSkills = createAsyncThunk(
  "resume/updateSoftSkills",
  async ({ softskillId, payload, onSuccess }, { rejectWithValue }) => {
    console.log(softskillId, payload, "addResumeSoftSkill>>>>>>>>>>>>>>>>");
    try {
      const { data } = await resumeRepo.updateSoftSkills(softskillId, payload);
      if (data?.status_code === 201) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to update Soft Skills");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error updating Soft Skills", err);
      return rejectWithValue(err.message);
    }
  }
);
export const addResumeSoftSkills = createAsyncThunk(
  "resume/addSoftSkills",
  async ({ resumeId, payload, onSuccess }, { rejectWithValue }) => {
    console.log(resumeId, payload, "addResumeSoftSkills>>>>>>>>>>>>>>>>");
    try {
      const { data } = await resumeRepo.addSoftSkills(resumeId, payload);
      if (data?.status_code === 201) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to add Soft Skills");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error adding Soft Skills", err);
      return rejectWithValue(err.message);
    }
  }
);

export const saveAiGeneratedResume = createAsyncThunk(
  "resume/saveAiGeneratedResume",
  async ({ resumeId, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.saveAiGeneratedResume(resumeId);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        throw new Error("Failed to add Publication");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error adding Publication", err);
      return rejectWithValue(err.message);
    }
  }
);

export const deleteResume = createAsyncThunk(
  "resume/deleteResume",
  async ({ resumeId, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await resumeRepo.deleteResume(resumeId);
      console.log(data, "delLanguages>>>>>>>>>>>>>>>>");
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      } else {
        error(error?.response.data.detail);
        throw new Error("Failed to delete Languages");
      }
    } catch (err) {
      error(err.response.data.detail);
      console.error("Error deleting Languages", err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

export const getResumeFeedback = createAsyncThunk(
  "resume/getResumeFeedback",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const data = await resumeRepo.getResumeFeedback(id);
      if (data) {
        onSuccess(data);
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  allResume: [],
  workExperience: [],
  profile: null,
  professionalSummaries: [],
  educations: [],
  categories: [],
  publications: [],
  certificates: [],
  projects: [],
  job: {},
  interests: {},
  languages: {},
  softskills: {},
  AiResume: {
    workExperience: [],
    profile: null,
    professionalSummaries: [],
    educations: [],
    categories: [],
    publications: [],
    certificates: [],
    projects: [],
    job: {},
    interests: {},
    languages: {},
    softskills: {},
  },
  getSingleResumeDoc: null,
  singleResumeLoading: false,
  contactId: null,
  isLoading: false,
  isAILoading: false,
};

const resumeBuilderSlice = createSlice({
  name: "resumeBuilder",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getResumeFeedback.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResumeFeedback.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getResumeFeedback.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteResume.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteResume.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteResume.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(addNewResume.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewResume.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addNewResume.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(uploadExistingResume.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadExistingResume.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(uploadExistingResume.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(updateResume.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateResume.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateResume.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(udpateResumeProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(udpateResumeProfile.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(udpateResumeProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(getResume.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResume.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allResume = action.payload;
      })
      .addCase(getResume.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(getSingleResume.pending, (state) => {
        state.isLoading = true;
        state.singleResumeLoading = true;
      })
      .addCase(getSingleResume.fulfilled, (state, action) => {
        state.singleResumeLoading = false;
        state.isLoading = false;
        state.profile = action.payload.contact;
        state.educations = action.payload.educations;
        state.professionalSummaries = action.payload.blurbs;
        state.certificates = action.payload.certifications;
        state.categories = action.payload.skills;
        state.projects = action.payload.projects;
        state.publications = action.payload.publications;
        state.workExperience = action.payload.work_experience;
        state.interests = action.payload?.interests[0];
        state.languages = action.payload?.languages[0];
        state.softskills = action.payload?.soft_skills[0];
        state.getSingleResumeDoc = action.payload;
      })
      .addCase(getSingleResume.rejected, (state, action) => {
        state.singleResumeLoading = false;
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(generateAIResume.pending, (state) => {
        state.isAILoading = true;
      })
      .addCase(generateAIResume.fulfilled, (state, action) => {
        console.log(action.payload, "generateAIResume");
        state.isAILoading = false;
        state.AiResume.profile = action.payload.contact;
        state.AiResume.professionalSummaries = action.payload.blurbs;
        state.AiResume.educations = action.payload.educations;
        state.AiResume.certificates = action.payload.certifications;
        state.AiResume.skills = action.payload.skills;
        state.AiResume.projects = action.payload.projects;
        state.AiResume.publications = action.payload.publications;
        state.AiResume.workExperience = action.payload.work_experience;
        state.AiResume.interests = action.payload?.interests?.[0]
          ? action.payload?.interests?.[0]
          : state.interests;
        state.AiResume.languages = action.payload.languages?.[0]
          ? action.payload.languages?.[0]
          : state.languages;
        state.AiResume.softskills = action.payload.soft_skills?.[0]
          ? action.payload.soft_skills?.[0]
          : state.softskills;
      })
      .addCase(generateAIResume.rejected, (state, action) => {
        state.isAILoading = false;
        state.err = action.payload;
      })
      .addCase(addResumeProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addResumeProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log(action.payload, "addResumeProfile");
        state.contactId = action.payload.id;
      })
      .addCase(addResumeProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(getResumeProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResumeProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profile = action.payload;
        state.contactId = action.payload.id;
      })
      .addCase(getResumeProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(addResumeBlurb.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addResumeBlurb.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addResumeBlurb.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(getResumeBlurb.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResumeBlurb.fulfilled, (state, action) => {
        state.isLoading = false;
        state.professionalSummaries = action.payload;
      })
      .addCase(getResumeBlurb.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(deleteResumeBlurb.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteResumeBlurb.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteResumeBlurb.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(getSkills.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSkills.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getSkills.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(addResumeSkill.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addResumeSkill.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addResumeSkill.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(updateResumeSkill.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateResumeSkill.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateResumeSkill.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(deleteResumeSkill.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteResumeSkill.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteResumeSkill.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(addResumeEducation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addResumeEducation.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addResumeEducation.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(getResumeEducation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResumeEducation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.educations = action.payload;
      })
      .addCase(getResumeEducation.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(deleteResumeEducation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteResumeEducation.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteResumeEducation.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(addResumeCertificate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addResumeCertificate.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addResumeCertificate.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(getResumeCertificate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResumeCertificate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.certificates = action.payload;
      })
      .addCase(getResumeCertificate.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(deleteResumeCertificate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteResumeCertificate.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteResumeCertificate.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(addResumeProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addResumeProjects.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addResumeProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(getResumeProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResumeProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projects = action.payload;
      })
      .addCase(getResumeProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(deleteResumeProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteResumeProjects.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteResumeProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(addResumeCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addResumeCompany.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addResumeCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(addResumeRoles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addResumeRoles.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addResumeRoles.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(udpateResumeCompaney.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(udpateResumeCompaney.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(udpateResumeCompaney.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(udpateResumeRoles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(udpateResumeRoles.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(udpateResumeRoles.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(deleteResumeRole.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteResumeRole.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteResumeRole.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(deleteResumeCompaney.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteResumeCompaney.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteResumeCompaney.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      // .addCase(getResumeExperience.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(getResumeExperience.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.workExperience = action.payload;
      // })
      // .addCase(getResumeExperience.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.err = action.payload;
      // })
      // .addCase(deleteResumeExperience.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(deleteResumeExperience.fulfilled, (state, action) => {
      //   state.isLoading = false;
      // })
      // .addCase(deleteResumeExperience.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.err = action.payload;
      // })
      .addCase(addResumePublication.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addResumePublication.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addResumePublication.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(deleteResumePublication.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteResumePublication.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteResumePublication.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(getResumePublication.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getResumePublication.fulfilled, (state, action) => {
        state.isLoading = false;
        state.publications = action.payload;
      })
      .addCase(getResumePublication.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(addResumeJob.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addResumeJob.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addResumeJob.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(getResumeJob.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getResumeJob.fulfilled, (state, action) => {
        console.log({ getJob: action.payload });
        state.isLoading = false;
        state.job = action.payload;
      })
      .addCase(getResumeJob.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(udpateResumeJob.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(udpateResumeJob.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(udpateResumeJob.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(addResumeCategory.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addResumeCategory.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addResumeCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(getResumeCategory.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getResumeCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload;
      })
      .addCase(getResumeCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      // .addCase(deleteResumeInterests.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(deleteResumeInterests.fulfilled, (state, action) => {
      //   state.isLoading = false;
      // })
      // .addCase(deleteResumeInterests.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.err = action.payload;
      // })
      .addCase(addResumeInterests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addResumeInterests.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addResumeInterests.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(updateResumeInterests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateResumeInterests.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateResumeInterests.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(getResumeInterests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResumeInterests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.interests = action.payload;
      })
      .addCase(getResumeInterests.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      // .addCase(deleteResumeLanguages.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(deleteResumeLanguages.fulfilled, (state, action) => {
      //   state.isLoading = false;
      // })
      // .addCase(deleteResumeLanguages.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.err = action.payload;
      // })
      .addCase(addResumeLanguages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addResumeLanguages.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addResumeLanguages.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(updateResumeLanguages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateResumeLanguages.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateResumeLanguages.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(getResumeLanguages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResumeLanguages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.languages = action.payload;
      })
      .addCase(getResumeLanguages.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(getResumeSoftSkills.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResumeSoftSkills.fulfilled, (state, action) => {
        state.isLoading = false;
        state.softskills = action.payload;
      })
      .addCase(getResumeSoftSkills.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(updateResumeSoftSkills.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateResumeSoftSkills.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateResumeSoftSkills.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(addResumeSoftSkills.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addResumeSoftSkills.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addResumeSoftSkills.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      })
      .addCase(saveAiGeneratedResume.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveAiGeneratedResume.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(saveAiGeneratedResume.rejected, (state, action) => {
        state.isLoading = false;
        state.err = action.payload;
      });
  },
});

export default resumeBuilderSlice.reducer;
