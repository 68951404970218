import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  border: 1px solid #ccc;
  padding: 4px 2px;
  border-radius: 6px;
  width: 60%;
  margin: auto;
  @media (max-width: 600px) {
    width: 90%;
    margin-top: 10px;
  }
`;

export const AttachmentButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 32px; /* Adjust size if needed */
  height: 32px; /* Adjust size if needed */
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"%3E%3Cpath fill="currentColor" fill-rule="evenodd" d="M9 7a5 5 0 0 1 10 0v8a7 7 0 1 1-14 0V9a1 1 0 0 1 2 0v6a5 5 0 0 0 10 0V7a3 3 0 1 0-6 0v8a1 1 0 1 0 2 0V9a1 1 0 1 1 2 0v6a3 3 0 1 1-6 0z" clip-rule="evenodd"%3E%3C/path%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: center;
`;

export const MicContainer = styled.button`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export const SendButton = styled.button`
  margin-right: 5px;
`;
