import { useDispatch, useSelector } from "react-redux";
import { fetchFileContent } from "../../../config/socketConfig";
import { changeTab, loadFile, openFileTab } from "../../../Redux/OctagonSlice";

export default function FileItem(props) {
  const { label, path, value } = props;
  // console.log(props);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.octagon);

  const handleClick = (evt) => {
    let fileInList = state.fileList.find((file) => file.path === path);
    if (!fileInList) {
      dispatch(openFileTab({ path }));
    } else {
      dispatch(changeTab(fileInList.key));
    }
    if (value === null) {
      fetchFileContent(path, (data) => {
        dispatch(
          loadFile({
            path: path.split("/").slice(0, -1).join("/"),
            value: data,
            fileName: label,
          })
        );
      });
    }
  };

  let indexOfFile = state.fileList.findIndex((file) => file.path === path);
  let isSelected = indexOfFile === state.activeFileKey;

  return (
    <button
      onClick={handleClick}
      className={`p-1 w-full text-left ${
        isSelected
          ? "bg-blue-400 hover:bg-blue-300 text-white"
          : "hover:bg-neutral-200"
      }}`}
    >
      {label}
    </button>
  );
}
