import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountryStates, getJobListings } from "../../Redux/NexaIqSlice";
import { useDebounce } from "use-debounce";
const { Option } = Select;
const FilterComponent = ({ storeActivePagination }) => {
  const dispatch = useDispatch();
  const { countryStates, stateCities } = useSelector((state) => state.nexaIq);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [postedDate, setPostedDate] = useState(null);
  const [jobSearch, setJobSearch] = useState("");
  console.log(jobSearch, "jobSearch");
  const [debouncedSearch] = useDebounce(jobSearch, 500);
  console.log(debouncedSearch, "jobdebouncedSearch");

  const clearFilter = () => {
    setSelectedCity(null);
    setPostedDate(null);
    setJobSearch("");
    // const payload = null;
    // dispatch(getJobListings({ payload, page: 1, onSuccess: () => {} }));
  };

  useEffect(() => {
    dispatch(
      getCountryStates({
        state: selectedState,
        onSuccess: () => {},
      })
    );
    setSelectedCity(null);
  }, [selectedState]);

  useEffect(() => {
    console.log("Applying Filter", selectedCity, postedDate, debouncedSearch);
    let payload = {};
    if (selectedCity) {
      payload = {
        ...payload,
        location: selectedCity,
      };
      console.log("Applying City Filter", payload);
    }

    if (postedDate) {
      payload = {
        ...payload,
        from_date: postedDate,
      };
      console.log("Applying Posted Date Filter", payload);
    }

    if (debouncedSearch) {
      payload = {
        ...payload,
        title: debouncedSearch.toLowerCase(),
      };
      console.log("Applying Debounce Search Filter", payload);
    }
    if (Object.keys(payload).length > 0) {
      dispatch(getJobListings({ payload, page: 1, onSuccess: () => {} }));
    } else {
      dispatch(getJobListings({ payload: null, page: 1, onSuccess: () => {} }));
    }
  }, [selectedCity, postedDate, debouncedSearch]);

  return (
    <>
      <div className="fillter"></div>
      <div className="wrappertop">
        <div className="filter-component bg-transparent hide-mob">
          <div className="flex h-full w-full items-center justify-center space-x-6 custom-max-width">
            <div className="w-[200px]">
              <Select
                placeholder="Select State"
                value={selectedState}
                onChange={(value) => setSelectedState(value)}
                className="w-full mt-1 h-[48px]"
              >
                {countryStates?.map((option) => (
                  <Option key={option.name} value={option.name}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="w-[200px]">
              <Select
                placeholder="Select City"
                value={selectedCity}
                disabled={!selectedState}
                onChange={(value) => setSelectedCity(value)}
                className="w-full mt-1 h-[48px]"
                title={!selectedState ? "Please select state first" : ""}
              >
                {stateCities?.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="w-[200px]">
              <Select
                placeholder="Date posted"
                value={postedDate}
                onChange={(value) => setPostedDate(value)}
                className="w-full mt-1 h-[48px]"
              >
                <Option value="any_time">Any Time</Option>
                <Option value="past_month">Past Month</Option>
                <Option value="past_week">Past Week</Option>
              </Select>
            </div>
            <div className="shrink-1">
              <div className="relative flex justify-center item-center">
                <input
                  type="text"
                  id="resumeName"
                  className="form-control"
                  placeholder="Job Search..."
                  value={jobSearch}
                  onChange={(e) =>
                    setJobSearch(e.target.value)
                  }
                />
                <span className="absolute inset-y-0 right-3 flex items-center">
                  <svg
                    class="w-4 h-4 text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </span>
                {/* <div className="py-[10px] px-[16px] cursor-default rounded-[8px] flex items-center border border-black bg-[#FAFFEB]">
                  <span className="text-sm font-semibold whitespace-nowrap max-w-[150px] text-ellipsis overflow-hidden">
                    Internship
                  </span>
                  <svg
                    width="17"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-1"
                  >
                    <path
                      d="m4.5 5.334-.715.781L8.5 10.83l4.714-4.714-.714-.781-4 4-4-4Z"
                      fill="#4D4D4D"
                    ></path>
                  </svg>
                </div> */}
              </div>
            </div>
            <div className="shrink-1">
              <button
                className="bg-[#FF0000] text-white rounded-lg py-2 px-6 border-none text-sm flex items-center gap-2 cursor-pointer"
                onClick={() => clearFilter()}
              >
                Clear Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterComponent;
