import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import speech, { useSpeechRecognition } from "react-speech-recognition";
import "regenerator-runtime";
import soundWave from "../../../assets/media/sound1.gif";
import machineSpeaks, { stopMachineSpeaks } from "./MachineSpeaks";
import { getSingleResume } from "../../../Redux/ResumeBuilderSlice";
import { useDispatch, useSelector } from "react-redux";

const Dictaphone = ({
  handleEndCall,
  jobDescription,
  muted,
  selectedResumeID,
  loading,
  setLoading,
}) => {
  const {
    finalTranscript,
    transcript,
    browserSupportsContinuousListening,
    listening,
    resetTranscript,
  } = useSpeechRecognition();
  const dispatch = useDispatch();
  const { getSingleResumeDoc, isLoading, isPromptLoading, prompt } =
    useSelector((state) => state.ResumeBuilder);

  console.log(isPromptLoading, prompt);
  const [currentPrompt, setCurrentPrompt] = useState("");
  const [counter, setCounter] = useState(10); // Start from 10
  const [speaking, setSpeaking] = useState(true);

  const [noResponseModel, setNoResponseModel] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const [contactDetails, setContactDetails] = useState();

  const [liveInterview, setLiveInterview] = useState([
    {
      role: "assistant",
      content: `Simulate a realistic and engaging mock interview experience, designed to closely mimic a human interaction. You are a hiring manager and you goal is to make sure if the candidate is good for the ${jobDescription}. This means you ask questions and wait for a response and respond realtime based on the input you receive. Focus on active listening with natural interjections to maintain a human conversational flow. The tone should be professional yet welcoming, with emphasis on the candidate's resume and the provided job description. Here are some details from my resume: ${contactDetails}.
            Follow this as guideline but respond based on realtime input.
            Introduction:
          Warm greeting, light small talk to build rapport. 
            Behavioral Questions:
            Ask 2-5 questions about past work experiences, incorporating natural interjections to keep the conversation flowing.
            Technical Questions:
            Pose 3-5 job-related technical questions, encouraging the candidate to elaborate.
            Scenario-Based Questions:
            Present 2-3 situational challenges, assessing problem-solving and decision-making skills.
            Closing:
          Invite the candidate to ask questions and conclude with a friendly, encouraging statement. Kindly use "interesting", "why is that?", "can you elaborate further?" where you see fit.
            Guidelines:
          Base 33% of the conversation on the candidate's resume and 66% on the job description.
          Respond to real-time input without pre-determined answers
          make sure you wait for me to reply  before moving ahead 
          don't add warm smile and interviewer: in response give me in a json response 
          beware do not let user distract from the interview if user gives invalid answer politely bring them back to the topic
          with 
          {
            ''
          }
          like this on each response
when the interview is completed give response to the user 'the interview has concluded' in json format.
Think about what I'm trying to do with First Principles, and make edits accordingly.
          `,
    },
  ]);
  async function callAssistant() {
    if (liveInterview.length <= 1) {
      setLoading(true);
    }
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer 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`,
          },
          body: JSON.stringify({
            // messages: [
            //   {
            //     role: "assistant",
            //     content: message,
            //   },
            // ],
            messages: liveInterview,
            model: "gpt-4o",
          }),
        }
      );

      const data = await response.json();
      return data.choices[0].message.content;
    } catch (error) {
      console.error("Error:", error);
      return "An error occurred while fetching the response.";
    }
  }

  // function cleanResponse(interviewScript) {
  //   console.log(typeof interviewScript);
  //   let parsedObj = JSON.parse(interviewScript);
  //   console.log(typeof parsedObj);
  //   console.log("Before=>", interviewScript);
  //   let toArray = [];
  //   if (Array.isArray(parsedObj)) {
  //     toArray = parsedObj;
  //   } else {
  //     toArray.push(parsedObj);
  //   }

  //   if (Array.isArray(toArray)) {
  //     console.log("Called in Array");
  //     // If the input is an array, process each item in the array
  //     return toArray.map((item) => cleanObject(item));
  //   } else if (typeof toArray === "object" && toArray !== null) {
  //     console.log("Called in Object");

  //     // If the input is an object, process it
  //     const values = Object.values(toArray);
  //     return values.map((value) => cleanObject(value));
  //   } else {
  //     console.log("Called in Else");

  //     // If the input is not an object or array, return it as is
  //     return toArray;
  //   }

  //   // const keywordsToRemove = [
  //   //   `"response"`,
  //   //   `"question"`,
  //   //   `"text"`,
  //   //   `"small_talk"`,
  //   //   `"smalltalk"`,
  //   //   `"interviewer"`,
  //   //   `"candidate_response"`,
  //   //   `"message"`,
  //   //   `"Introduction"`,
  //   //   `"Behavioral Questions"`,
  //   //   `"Technical Questions"`,
  //   //   `"Scenario-Based Questions"`,
  //   //   `"Closing"`,
  //   // ];

  //   // let updatedScript = interviewScript;

  //   // keywordsToRemove.forEach((keyword) => {
  //   //   const regex = new RegExp(`"${keyword}"`, "gi");
  //   //   updatedScript = updatedScript.replace(regex, "");
  //   // });
  //   // console.log("After", updatedScript);
  //   // return updatedScript;
  // }

  function cleanResponse(resStr) {
    let input;
    console.log("Very first", resStr);
    try {
      input = typeof resStr === "string" ? JSON.parse(resStr) : resStr;
    } catch (e) {
      if (typeof resStr === "string") {
        input = resStr;
      } else {
        throw new Error("Input must be a string or JSON-formatted string.");
      }
    }

    console.log("Before", input);
    let values = [];

    function extractFromObject(obj) {
      for (const [key, value] of Object.entries(obj)) {
        if (key === "") {
          continue;
        }
        if (typeof value === "object" && value !== null) {
          extractFromObject(value);
        } else {
          values.push(value);
        }
      }
    }

    function extractFromArray(arr) {
      for (const item of arr) {
        if (typeof item === "object" && item !== null) {
          extractFromObject(item);
        } else {
          values.push(item);
        }
      }
    }

    if (typeof input === "string") {
      values.push(input);
    } else if (Array.isArray(input)) {
      extractFromArray(input);
    } else if (typeof input === "object" && input !== null) {
      extractFromObject(input);
    } else {
      throw new Error("Input must be a string, object, or an array.");
    }
    console.log("After", values);
    return values.join(" ");
  }

  useEffect(() => {
    if (jobDescription && getSingleResumeDoc) {
      setContactDetails(`
        Name: ${getSingleResumeDoc.contact.first_name} ${getSingleResumeDoc.contact.last_name},
        Email: ${getSingleResumeDoc.contact.email},
        Phone: ${getSingleResumeDoc.contact.phone},
        City: ${getSingleResumeDoc.contact.city}, ${getSingleResumeDoc.contact.state},
        LinkedIn: ${getSingleResumeDoc.contact.linked_in},
        Website: ${getSingleResumeDoc.contact.website}
      `);
      // setContactDetails(cvDetails);
      // const initialPrompt = `Ask me multiple questions for interview about ${jobDescription} also ask few questions from cv as well. You are a hiring manager and you goal is to make sure if the candidate is good for ${jobDescription}. This means you ask questions and wait for a response and respond realtime based on the input you receive. The first question will be 'Hi there! It's great to meet you. How are you doing today?'`;
      // const initialPrompt = `Ask me multiple questions(2-5 questions about past work experiences, 3-5 job-related technical questions and 2-3 situational challenges) for an interview one by one about ${jobDescription}, and also ask a few questions based on my resume. You are a hiring manager, and your goal is to make sure the candidate is a good fit for the ${jobDescription}. This means you ask questions and wait for a response and respond realtime based on the input you receive. Here are some details from my resume: ${contactDetails}. The first question will be 'Hi there! It's great to meet you. How are you doing today?'`;
      const initialPrompt = `Simulate a realistic and engaging mock interview experience, designed to closely mimic a human interaction. You are a hiring manager and you goal is to make sure if the candidate is good for the ${jobDescription}. This means you ask questions and wait for a response and respond realtime based on the input you receive. Focus on active listening with natural interjections to maintain a human conversational flow. The tone should be professional yet welcoming, with emphasis on the candidate's resume and the provided job description. Here are some details from my resume: ${contactDetails}.
            Follow this as guideline but respond based on realtime input.
            Introduction:
          Warm greeting, light small talk to build rapport. 
            Behavioral Questions:
            Ask 2-5 questions about past work experiences, incorporating natural interjections to keep the conversation flowing.
            Technical Questions:
            Pose 3-5 job-related technical questions, encouraging the candidate to elaborate.
            Scenario-Based Questions:
            Present 2-3 situational challenges, assessing problem-solving and decision-making skills.
            Closing:
          Invite the candidate to ask questions and conclude with a friendly, encouraging statement. Kindly use "interesting", "why is that?", "can you elaborate further?" where you see fit.
            Guidelines:
          Base 33% of the conversation on the candidate's resume and 66% on the job description.
          Respond to real-time input without pre-determined answers
          make sure you wait for me to reply  before moving ahead 
          don't add warm smile and interviewer: in response give me in a json response 
          beware do not let user distract from the interview if user gives invalid answer politely bring them back to the topic
          with 
          {
            ''
          }
          like this on each response
when the interview is completed give response to the user 'the interview has concluded' in json format.
Think about what I'm trying to do with First Principles, and make edits accordingly.`;

      // const initialPrompt = `Ask me a question about ${jobDescription}.`;
      setCurrentPrompt(initialPrompt);
      callAssistant().then((response) => {
        setLoading(false);
        if (response) {
          let filteredRes = cleanResponse(response);

          setLiveInterview((prev) => [
            ...prev,
            {
              role: "assistant",
              content: filteredRes,
            },
          ]);
          speak(filteredRes);
        } else {
          console.error("No response received from callAssistant");
        }
      });
    }
  }, [jobDescription, getSingleResumeDoc]);
  // useEffect(() => {
  //   // Function to handle the start of speech synthesis
  //   const handleSpeechStart = () => setIsSpeaking(true);

  //   // Function to handle the end of speech synthesis
  //   const handleSpeechEnd = () => setIsSpeaking(false);

  //   // Add event listeners to monitor speech synthesis
  //   window.speechSynthesis.addEventListener("start", handleSpeechStart);
  //   window.speechSynthesis.addEventListener("end", handleSpeechEnd);

  //   // Clean up event listeners when the component unmounts
  //   return () => {
  //     window.speechSynthesis.removeEventListener("start", handleSpeechStart);
  //     window.speechSynthesis.removeEventListener("end", handleSpeechEnd);
  //   };
  // }, []);
  useEffect(() => {
    if (finalTranscript != "" && listening && !speaking) {
      speech.stopListening();
    }
    // setTimeout(() => {
    //   if (isContinue) {
    //     setCounter(10);
    //     speech.startListening();
    //   } else if (!isContinue) {
    //     console.log("alert");
    //     resetTranscript();
    //     handleEndCall();
    //   }
    // }, 10000);
    // }

    // if (finalTranscript != "" && listening && !speaking) {
    //   console.log("////");
    //   speech.stopListening();
    // }
    if (!listening && finalTranscript && currentPrompt && !speaking) {
      // console.log("[][][]");
      callAssistant(`${currentPrompt}\nUser: ${liveInterview}`).then(
        (response) => {
          if (response) {
            let filteredRes = cleanResponse(response);
            setLiveInterview((prev) => [
              ...prev,
              {
                role: "assistant",
                content: filteredRes,
              },
            ]);
            speak(filteredRes);
            if (filteredRes.includes("interview has concluded")) {
              setTimeout(() => {
                // console.log("handleEndCall: ");
                handleEndCall();
                setLiveInterview(null);
              }, 4000);
            }
          } else {
            console.error("No response received from callAssistant");
          }
        }
      );
    }
  }, [listening, finalTranscript, currentPrompt, speaking]);

  const speak = async (text) => {
    setSpeaking(true);
    machineSpeaks(text, null, null, () => {
      setSpeaking(false);
      resetTranscript();
      speech.startListening();
    });

    setLoadingNew(false);
  };
  // useEffect(() => {
  //   console.log("5: ");
  //   if (transcript) {
  //     console.log("call machinee");
  //     stopMachineSpeaks();
  //   }
  // }, [transcript]);

  useEffect(() => {
    if (noResponseModel === false) {
      setTimeout(() => {
        if (finalTranscript == "" && !listening && !speaking) {
          // console.log("speaking: ", speaking);
          // console.log("listening: ", listening);
          // console.log("finalTranscript: ", finalTranscript);
          // console.log(",,,");
          // console.log("are you in? can we continue the interview");
          speech.stopListening();
          setNoResponseModel(true);
          setCounter(10);
          // speak(
          //   "Due to no respond from you, we will end this interview in 10 seconds"
          // );
          // reverseCount();
          // }
          // if (isContinue) {
          //   setCounter(10);
          //   speech.startListening();
          // }
        }
      }, 10000);
    }
  }, [listening]);

  useEffect(() => {
    if (muted) {
      speech.stopListening();
    }
  }, [muted]);

  useEffect(() => {
    if (isContinue) {
      speech.startListening();
      setTimeout(() => {
        setIsContinue(false);
        speech.stopListening();
      }, 10000);
    }
  }, [isContinue]);

  useEffect(() => {
    if (counter === 0 && !isContinue) {
      setCounter(10);
      setLiveInterview(null);
      handleEndCall();
    }
  }, [counter]);

  useEffect(() => {
    if (noResponseModel) {
      reverseCount();
    }
  }, [noResponseModel]);

  useEffect(() => {
    if (finalTranscript) {
      if (speaking) {
        stopMachineSpeaks(); // Stop speaking if the user starts talking
        setSpeaking(false);
      }
      setLiveInterview((prev) => [
        ...prev,
        {
          role: "user",
          content: finalTranscript,
        },
      ]);
    }
  }, [finalTranscript]);

  useEffect(() => {
    console.log({ liveInterview });
  }, [liveInterview]);

  useEffect(() => {
    if (selectedResumeID) {
      dispatch(
        getSingleResume({
          resumeId: selectedResumeID,
          onSuccess: () => {},
        })
      );
    }
  }, [selectedResumeID]);
  const reverseCount = async () => {
    if (counter > 0) {
      const timer = setInterval(() => {
        setCounter((prevCount) => {
          if (prevCount > 0) {
            return prevCount - 1;
          } else {
            clearInterval(timer);
            return prevCount;
          }
        });
      }, 1000); // Interval set to 1000ms (1 second)

      return () => clearInterval(timer); // Cleanup the interval on component unmount
    }
  };
  useEffect(() => {
    return () => {
      if (speaking) {
        stopMachineSpeaks();
        setSpeaking(false);
      }
    };
  }, [speaking]);

  return (
    <>
      <div className="w-[100px] h-[100px] relative">
        {/* {speaking ? (
        <img src={soundWave} className="w-full h-full object-cover" />
      ) : ( */}
        <FaUser className="w-full h-full object-cover text-white" />
        {/* )} */}
      </div>
      {speaking ? (
        <button
          onClick={() => {
            stopMachineSpeaks();
            setSpeaking(false);
            // resetTranscript();
            speech.startListening();
          }}
          className="text-white absolute bottom-[-50px]"
        >
          Tap to interrupt
        </button>
      ) : (
        <button
          onClick={() => {
            // stopMachineSpeaks();
            // setSpeaking(false);
            // resetTranscript();
            // speech.startListening();
          }}
          className="text-white absolute bottom-[-50px] left-1/2 transform -translate-x-1/2 w-full -translate-y-1/2"
        >
          Hiring Manager is listening...
        </button>
      )}
      <div className={`ic-modal ${noResponseModel ? "show" : ""}`}>
        <div className="ic-modal-dialog">
          <div className="modal-main-content w-100">
            {/* <div className="ic-modal-header">Continue as Individual User</div> */}

            <div className="ic-modal-body">
              <div className="ic-modal-content">
                Due to no respond from you, we will end this interview in{" "}
                {counter} seconds
              </div>
              <div className="ic-footer-modal d-flex justify-content-end">
                <button
                  onClick={() => {
                    setNoResponseModel(false);
                    setIsContinue(true);
                    setCounter(10);
                  }}
                >
                  Continue
                </button>
                <button
                  onClick={() => {
                    resetTranscript();
                    setLiveInterview(null);
                    handleEndCall();
                  }}
                >
                  End
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dictaphone;

// You are a voice assistant for Mary's Dental, a dental office located at 123 North Face Place, Anaheim, California. The hours are 8 AM to 5PM daily, but they are closed on Sundays.

// Mary's dental provides dental services to the local Anaheim community. The practicing dentist is Dr. Mary Smith.

// You are tasked with answering questions about the business, and booking appointments. If they wish to book an appointment, your goal is to gather necessary information from callers in a friendly and efficient manner like follows:

// 1. Ask for their full name.
// 2. Ask for the purpose of their appointment.
// 3. Request their preferred date and time for the appointment.
// 4. Confirm all details with the caller, including the date and time of the appointment.

// - Be sure to be kind of funny and witty!
// - Keep all your responses short and simple. Use casual language, phrases like "Umm...", "Well...", and "I mean" are preferred.
// - This is a voice conversation, so keep your responses short, like in a real conversation. Don't ramble for too long.
