// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headers {
  padding: 20px 0;
  position: static;
}

.next-btn {
  width: fit-content;
  font-size: 16px;
  margin-top: 20px;
}
.chip-btn {
  width: fit-content;
  font-size: 16px;
  background-color: #eaecf0;
  color: #2b6be2;
  margin: 5px;
}

.radio {
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.radio-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.radio-wrapper label {
  font-size: 18px;
  font-weight: 500;
}
.radio-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 0;
}

.brand img {
  height: 150px;
  object-fit: contain;
  width: 400px;
}

.greetings {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-container {
  margin-top: 20px;
  text-align: center;
  display: flex;
  gap: 20px;
}

.video-container iframe {
  max-width: 100%;
  height: 300px;
}

@media only screen and (max-width: 1440px) {
  .video-container {
    flex-direction: column;
  }
}
@media only screen and (max-width: 767.98px) {
  .video-container {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Onboarding/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,cAAc;EACd,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,eAAe;EACf,aAAa;AACf;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF;AACA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".headers {\n  padding: 20px 0;\n  position: static;\n}\n\n.next-btn {\n  width: fit-content;\n  font-size: 16px;\n  margin-top: 20px;\n}\n.chip-btn {\n  width: fit-content;\n  font-size: 16px;\n  background-color: #eaecf0;\n  color: #2b6be2;\n  margin: 5px;\n}\n\n.radio {\n  height: 18px;\n  width: 18px;\n  cursor: pointer;\n}\n.radio-wrapper {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n.radio-wrapper label {\n  font-size: 18px;\n  font-weight: 500;\n}\n.radio-container {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  padding: 30px 0;\n}\n\n.brand img {\n  height: 150px;\n  object-fit: contain;\n  width: 400px;\n}\n\n.greetings {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.video-container {\n  margin-top: 20px;\n  text-align: center;\n  display: flex;\n  gap: 20px;\n}\n\n.video-container iframe {\n  max-width: 100%;\n  height: 300px;\n}\n\n@media only screen and (max-width: 1440px) {\n  .video-container {\n    flex-direction: column;\n  }\n}\n@media only screen and (max-width: 767.98px) {\n  .video-container {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
