import { useState } from "react";
import FileItem from "./FileItem";
import { fetchDir } from "../../../config/socketConfig";
import { loadDir, newFile } from "../../../Redux/OctagonSlice";
import { useDispatch } from "react-redux";

export default function DirectoryItem(props) {
  const { label, childDirs, childFiles, path } = props;
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const handleDirClick = (evt) => {
    setIsOpen((prev) => !prev);
    console.log(path, childDirs, childFiles);
    if (childDirs !== null || childFiles !== null) return;
    fetchDir(path, (data) => dispatch(loadDir({ path, ...data })));
  };

  const handleNewFile = (evt) => {
    console.log("new file", path);
    dispatch(newFile({ name: "untitled", dirPath: path }));
  };

  return (
    <div>
      <button
        className="hover:bg-neutral-200 p-1 w-full text-left flex justify-between"
        onClick={handleDirClick}
      >
        <span>
          📁
          {label}
        </span>
        <span>
          <button onClick={handleNewFile}>+</button>
        </span>
      </button>
      {isOpen && (childDirs === null || childFiles === null) && "Loading..."}
      {isOpen && childDirs !== null && (
        <ul className="ml-3">
          {childDirs.map((dir) => (
            <DirectoryItem {...dir} />
          ))}
        </ul>
      )}
      {isOpen && childFiles !== null && (
        <ul className="ml-4">
          {childFiles.map((file) => (
            <FileItem {...file} />
          ))}
        </ul>
      )}
    </div>
  );
}
