// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-wrap-div {
  overflow: hidden;
  width: 100vw;
}
.carousel-div {
  /* height: 100vh; */
  display: flex;
  position: relative;
  transition: all 1s;
}
.slide-div {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  margin: auto;
  align-items: center;
  flex-shrink: 0;
  transition: all 1s;
  overflow: auto;
}
.career_slide-cover {
  padding: 10px 0px 10px 0px;
  overflow: auto;
  width: 100%;
  height: fit-content;
}
.slide {
  padding: 80px 0px 20px 0px;
  overflow: auto;
  width: 100%;
  height: fit-content;
  max-height: 100vh;
  padding: 0 15px;
  margin-top: 50px;
}
.cardBody {
  background: #f7f8fa;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.footer_scrore {
  height: 50px;
  width: 100%;
  background: #9eeaf9;
  border-radius: 10px;
  padding: 1rem 1rem;
  align-items: center;
}
.score {
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #198754;
  border-radius: 50%;
  height: auto;
  width: auto;
  padding: 8px;
  font-size: 15px;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/InterviewRole/components/interview.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,cAAc;EACd,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,0BAA0B;EAC1B,cAAc;EACd,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,0BAA0B;EAC1B,cAAc;EACd,WAAW;EACX,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,eAAe;EACf,WAAW;AACb","sourcesContent":[".carousel-wrap-div {\n  overflow: hidden;\n  width: 100vw;\n}\n.carousel-div {\n  /* height: 100vh; */\n  display: flex;\n  position: relative;\n  transition: all 1s;\n}\n.slide-div {\n  width: 100%;\n  /* height: 100vh; */\n  display: flex;\n  margin: auto;\n  align-items: center;\n  flex-shrink: 0;\n  transition: all 1s;\n  overflow: auto;\n}\n.career_slide-cover {\n  padding: 10px 0px 10px 0px;\n  overflow: auto;\n  width: 100%;\n  height: fit-content;\n}\n.slide {\n  padding: 80px 0px 20px 0px;\n  overflow: auto;\n  width: 100%;\n  height: fit-content;\n  max-height: 100vh;\n  padding: 0 15px;\n  margin-top: 50px;\n}\n.cardBody {\n  background: #f7f8fa;\n  border-radius: 10px;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  margin-bottom: 10px;\n}\n.footer_scrore {\n  height: 50px;\n  width: 100%;\n  background: #9eeaf9;\n  border-radius: 10px;\n  padding: 1rem 1rem;\n  align-items: center;\n}\n.score {\n  margin-left: 5px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: #198754;\n  border-radius: 50%;\n  height: auto;\n  width: auto;\n  padding: 8px;\n  font-size: 15px;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
