import React, { useCallback, useState } from "react";
import { sendMessageAction, updateChatMessages } from "../../Redux/ChatSlice";
import { sendMessage } from "../../config/socketConfig";
import { useDispatch, useSelector } from "react-redux";

export default function ChatFooter() {
  const dispatch = useDispatch();
  const { singleChat } = useSelector((state) => state.chat);
  const [input, setInput] = useState("");

  const handleSendMessage = useCallback(
    (e) => {
      e.preventDefault();

      if (input.trim()) {
        const messageData = {
          chat_id: singleChat?.id,
          content: input,
        };
        dispatch(
          sendMessageAction({
            messageData,
            onSuccess: (response) => {
              let senderMessages = { ...response, is_my_message: true };
              let receiverMessages = { ...response, is_my_message: false };
              dispatch(updateChatMessages(senderMessages));
              sendMessage(receiverMessages);
              setInput("");
            },
          })
        );
      }
    },
    [input, singleChat]
  );
  return (
    <form onSubmit={handleSendMessage} className="flex sm:px-4 px-1">
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        className="flex-grow p-2 rounded-l"
        placeholder="Type a message..."
      />
      <button type="submit" className="bg-blue-500 text-white p-2 rounded-r">
        Send
      </button>
    </form>
  );
}
