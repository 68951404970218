import { useState, useEffect, useRef } from "react";
import Header from "../../Components/header";
import MeetScreen from "./Components/MeetScreen";
import { stopMachineSpeaks } from "./Components/MachineSpeaks";
import { useDispatch, useSelector } from "react-redux";
import { getResume, getSingleResume } from "../../Redux/ResumeBuilderSlice";
import {
  getPromptById,
  simulatorProBegin,
} from "../../Redux/SimulatorProSlice";
import { error } from "../../Utilities/toast";
import speech, { useSpeechRecognition } from "react-speech-recognition";
import SpeechRecognition from "react-speech-recognition";
import { FaRegFilePdf } from "react-icons/fa";
import { Link } from "react-router-dom";

const SimulatorPro = () => {
  const canvasRef = useRef(null);
  const dispatch = useDispatch();
  const { allResume } = useSelector((state) => state.ResumeBuilder);

  const { sessionId, gettingSessionId } = useSelector(
    (state) => state.SimulatorProSession
  );
  const [jobDescription, setJobDescription] = useState("");
  const [videoStream, setVideoStream] = useState(null);
  const [audioStream, setAudioStream] = useState(null);
  const [startMeet, setStartMeet] = useState(false);
  const [muted, setMuted] = useState(undefined);
  const [selectedResumeID, setSelectedResumeID] = useState("");
  const [contactDetails, setContactDetails] = useState("");

  const [step, setStep] = useState(1);
  const [isOpenAccordion, setIsOpenAccordion] = useState("upload");
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleDetailsOpen = (type) => {
    setIsOpenAccordion(type);
    setUploadedFile(null);
    setSelectedResumeID("");
  };

  const fileInput = useRef(null);

  const getFile = (e) => {
    const file = e.target.files[0];
    if (file.type === "application/pdf") {
      if (!file) return;
      const fileName =
        file.name.length >= 50
          ? file.name.substring(0, 51) + "..." + file.name.split(".")[1]
          : file.name;

      if (file && file.size > 2.5 * 1024 * 1024) {
        error("File size exceeds 2.5MB, please select a smaller file.");
        return;
      }

      setUploadedFile(file);
    } else {
      error("Only PDF file Supported");
    }
  };

  const { listening } = useSpeechRecognition();

  useEffect(() => {
    if (startMeet) {
      requestVideo();
      requestAudio();
    }
  }, [startMeet]);

  const handleEndCall = () => {
    if (videoStream) {
      const videoTracks = videoStream.getTracks();
      videoTracks.forEach((track) => track.stop());
      setVideoStream(null);
    }
    if (audioStream) {
      const audioTracks = audioStream.getTracks();
      audioTracks.forEach((track) => track.stop());
      setAudioStream(null);
      setMuted(undefined);
    }
    setSelectedResumeID("");
    setJobDescription("");
    setStartMeet(false);
    stopMachineSpeaks();
  };

  useEffect(() => {
    if (audioStream && canvasRef.current) {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(audioStream);
      source.connect(analyser);

      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const canvas = canvasRef.current;
      const canvasCtx = canvas.getContext("2d");
      const drawVisualizer = () => {
        requestAnimationFrame(drawVisualizer);
        analyser.getByteFrequencyData(dataArray);

        const width = canvas.width;
        const height = canvas.height;
        const barWidth = 10;
        canvasCtx.clearRect(0, 0, width, height);
        let x = 0;

        dataArray.forEach((item) => {
          const barHeight = (item / 255) * height * 1.1;
          canvasCtx.strokeStyle = `gray`;
          x += barWidth;
          canvasCtx.beginPath();
          canvasCtx.lineCap = "round";
          canvasCtx.lineWidth = 2;
          canvasCtx.moveTo(x, height);
          canvasCtx.lineTo(x, height - barHeight);
          canvasCtx.stroke();
        });
      };

      drawVisualizer();

      return () => {
        audioContext.close();
      };
    }
  }, [audioStream]);

  const requestVideo = () => {
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          setVideoStream(stream);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const requestAudio = () => {
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          setAudioStream(stream);
          // setMuted(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleMute = () => {
    if (audioStream && listening) {
      audioStream.getTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
      SpeechRecognition.stopListening();
      setMuted(true);
    } else {
      requestAudio();
      SpeechRecognition.startListening({ continuous: true });
      setMuted(false);
    }
  };

  const handleSubmitJD = async (e) => {
    e.preventDefault();
    if (jobDescription.length > 0 && (selectedResumeID || uploadedFile)) {
      let formData = new FormData();
      formData.append("jobDescription", jobDescription);
      if (uploadedFile) {
        formData.append("resumeFile", uploadedFile);
      } else {
        formData.append("resumeId", selectedResumeID);
      }
      // dispatch(
      //   getSingleResume({ resumeId: selectedResumeID, onSuccess: () => {} })
      // );
      dispatch(
        simulatorProBegin({
          payload: formData,
          onSuccess: (res) => {
            setContactDetails(res?.resume_text);
            setStartMeet(true);
            setStep(1);
            setIsOpenAccordion("upload");
            setUploadedFile(null);
            setSelectedResumeID("");
          },
          onError: (err) => {
            error(err?.data?.detail);
          },
        })
      );
    } else {
      error("Please enter your Job Description");
    }
  };
  const handleCamera = () => {
    if (videoStream) {
      const videoTracks = videoStream.getTracks();
      videoTracks.forEach((track) => track.stop());
      setVideoStream(null);
    } else {
      requestVideo();
    }
  };

  useEffect(() => {
    dispatch(getResume({ onSuccess: () => {} }));
    dispatch(getPromptById("simulator_pro"));
    // dispatch(simulatorProBegin());
  }, []);

  return (
    <div>
      <Header />
      <div
        className="ic-interview-full-body"
        style={{ backgroundImage: `url(${"/images/interview-banner.png"})` }}
      >
        {startMeet ? (
          <MeetScreen
            videoStream={videoStream}
            audioStream={audioStream}
            handleMute={handleMute}
            handleCamera={handleCamera}
            handleEndCall={handleEndCall}
            setStartMeet={setStartMeet}
            muted={muted}
            setMuted={setMuted}
            jobDescription={jobDescription}
            sessionId={sessionId}
            contactDetails={contactDetails}
          />
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <div className="ic-mockInterview">
              <div className="ic-mock-interview">
                {step === 1 && (
                  <>
                    <div className="grid divide-y divide-neutral-200 max-w-xl mx-auto">
                      <div className="py-4">
                        <div className="group">
                          <div
                            className="flex justify-between items-center font-medium cursor-pointer"
                            onClick={() => handleDetailsOpen("upload")}
                          >
                            <span>
                              Upload Your Resume For Personal Interview Coming
                              Up
                            </span>
                            <span
                              className={`transition transform ${
                                isOpenAccordion === "upload" ? "rotate-180" : ""
                              }`}
                            >
                              <svg
                                fill="none"
                                height="24"
                                shape-rendering="geometricPrecision"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                                viewBox="0 0 24 24"
                                width="24"
                              >
                                <path d="M6 9l6 6 6-6"></path>
                              </svg>
                            </span>
                          </div>

                          {isOpenAccordion === "upload" && (
                            <div className="pt-4 animate-fadeIn">
                              <form action="#">
                                <input
                                  type="file"
                                  name="file"
                                  ref={fileInput}
                                  accept=".pdf"
                                  hidden
                                  onChange={(e) => getFile(e)}
                                />
                                <div
                                  className="ic-file-upload"
                                  onClick={() => {
                                    fileInput.current.click();
                                  }}
                                >
                                  <div className="ic-icon">
                                    <img
                                      src="/images/document-upload.png"
                                      alt="Upload Document"
                                      style={{ width: "auto" }}
                                    />
                                  </div>
                                  <p className="support-text cursor-pointer">
                                    <span className="primary">
                                      Click to Upload
                                    </span>{" "}
                                    or drag and drop
                                  </p>
                                  <span className="ic-file-size">
                                    (Max. File size: 2.5 MB)
                                  </span>
                                </div>
                              </form>

                              {uploadedFile && (
                                <div className="ic-loading-area">
                                  <ul>
                                    <li>
                                      <div className="file-name">
                                        <div className="file-icon">
                                          {/* <img
                                            src="/images/document-upload.png"
                                            alt="File Icon"
                                          /> */}
                                          <FaRegFilePdf
                                            size={24}
                                            color="#2B6BE2"
                                          />
                                        </div>
                                        <div className="file-size">
                                          <div>
                                            <p>{uploadedFile.name}</p>
                                            {uploadedFile.size && (
                                              <span>{uploadedFile.size}</span>
                                            )}
                                          </div>
                                          {uploadedFile.loading === 100 && (
                                            <i className="ri-checkbox-circle-fill"></i>
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="py-4">
                        <div className="group">
                          <div
                            className="flex justify-between items-center font-medium cursor-pointer"
                            onClick={() => handleDetailsOpen("noUpload")}
                          >
                            <span>Select From Existing Resumes?</span>
                            <span
                              className={`transition transform ${
                                isOpenAccordion === "noUpload"
                                  ? "rotate-180"
                                  : ""
                              }`}
                            >
                              <svg
                                fill="none"
                                height="24"
                                shape-rendering="geometricPrecision"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                                viewBox="0 0 24 24"
                                width="24"
                              >
                                <path d="M6 9l6 6 6-6"></path>
                              </svg>
                            </span>
                          </div>

                          {isOpenAccordion === "noUpload" && (
                            <form className="w-full py-4 px-2 mt-3 animate-fadeIn">
                              <fieldset>
                                <div className="relative border border-gray-300 text-gray-800 bg-white shadow-lg">
                                  {allResume?.filter(
                                    (item) => item?.isCompleted
                                  ).length > 0 ? (
                                    <>
                                      <select
                                        className="appearance-none w-full py-2 bg-white rounded leading-tight focus:outline-none focus:shadow-outline"
                                        name="whatever"
                                        value={selectedResumeID}
                                        onChange={(e) =>
                                          setSelectedResumeID(e.target.value)
                                        }
                                        id="frm-whatever"
                                      >
                                        <option value="" className="py-2">
                                          Select A Resume&hellip;
                                        </option>
                                        {allResume
                                          ?.filter((item) => item?.isCompleted)
                                          ?.map((resume) => {
                                            return (
                                              <option
                                                value={resume?.id}
                                                className="py-2"
                                                key={resume?.id}
                                              >
                                                {resume?.resume_name
                                                  ? `${resume.resume_name}(${
                                                      resume?.iterations
                                                    })${
                                                      resume.company_name
                                                        ? ` - ${resume.company_name}`
                                                        : ""
                                                    }`
                                                  : `${resume?.contact?.first_name} ${resume?.contact?.last_name}`}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                                        <svg
                                          className="h-4 w-4"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                        >
                                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                      </div>
                                    </>
                                  ) : (
                                    <p className="text-yellow-600 p-1">
                                      You currently don't have any completed
                                      resumes in your profile. Please visit the{" "}
                                      <Link
                                        to="/resumes"
                                        className="text-yellow-600 underline"
                                      >
                                        Resume Page
                                      </Link>{" "}
                                      to create and complete a resume for use in
                                      Nexa.
                                    </p>
                                  )}
                                </div>
                              </fieldset>
                            </form>
                          )}

                          {(selectedResumeID !== "" || uploadedFile) && (
                            <div
                              className="mt-2 ic-btn"
                              type="button"
                              onClick={() => setStep(2)}
                            >
                              Next
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {step === 2 && (
                  <div>
                    <h4>Tell us about your Job Description</h4>
                    <div className="text-area">
                      <textarea
                        placeholder="Type your Job Description..."
                        required
                        value={jobDescription}
                        onChange={(e) => setJobDescription(e.target.value)}
                      />
                    </div>
                    <button onClick={handleSubmitJD} className="ic-btn mt-3">
                      Start Interview <i className="ri-file-list-line"></i>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SimulatorPro;
