import { combineReducers } from "@reduxjs/toolkit";
import StepsReducer from "../features/companyinformation/StepsSlice";
import authenticateSlicer from "./authenticate/authenticate.slicer";
import DashboardSlice from "./DashboardSlice";
import globalStatesSlice from "./GlobalStates";
import PaymentsSlice from "./PaymentsSlice";
import ProfileAnalysticsSlice from "./ProfileAnalysticsSlice";
import ResumeBuilderSlice from "./ResumeBuilderSlice";
import BlogSlice from "./BlogSlice";
import ChatSlice from "./ChatSlice";
import TaskSlice from "./TaskSlice";
import TicketSlice from "./TicketSlice";
import OctagonSlice from "./OctagonSlice";
import SimulatorProSlice from "./SimulatorProSlice";
import NexaIqSlice from "./NexaIqSlice"
const rootReducer = combineReducers({
  globalState: globalStatesSlice,
  authenticate: authenticateSlicer,
  dashboard: DashboardSlice,
  profile: ProfileAnalysticsSlice,
  Steps: StepsReducer,
  ResumeBuilder: ResumeBuilderSlice,
  SimulatorProSession: SimulatorProSlice,
  paymentMethods: PaymentsSlice,
  blog: BlogSlice,
  chat: ChatSlice,
  tasks: TaskSlice,
  tickets: TicketSlice,
  octagon: OctagonSlice,
  nexaIq: NexaIqSlice,
});

export default rootReducer;
