import dayjs from "dayjs";
import React from "react";

const Message = React.memo(({ message }) => {
  return (
    <div
      className={`p-2 my-2 rounded flex flex-col gap-2 ${
        message.is_my_message
          ? "bg-blue-500 text-white sm:w-5/6 w-full ml-auto"
          : "bg-gray-800 text-white sm:w-5/6 w-full"
      }`}
    >
      <p>{message.content}</p>
      <small className="flex justify-end">
        {dayjs(message?.created_at).format("hh:mm A")}
      </small>
    </div>
  );
});

export default Message;
