import React, { useEffect, useState } from "react";
import JobListing from "../../Components/Autoapply/JobListing";
import "./style.css";
import FilterComponent from "../../Components/Autoapply/FilterComponent";
import Header from "../../Components/header";
import { useDispatch, useSelector } from "react-redux";
import { getJobListings, getStates } from "../../Redux/NexaIqSlice";
import Loader from "../../Components/main-loader";
import Pagination from "../../Utilities/Pagination";

const AutoApply = () => {
  const dispatch = useDispatch();
  const { loading, jobs, reason, jobs_found, totalPages, currentPage } =
    useSelector((state) => state.nexaIq);
  const [activeTab, setActiveTab] = useState("Jobs");
  const [storeActivePagination, setStoreActivePagination] = useState(1);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handlePaginationChange = (value) => {
    console.log(value, "valuebyahsan");
    setStoreActivePagination(value);
    dispatch(
      getJobListings({
        payload: null,
        page: value,
        onSuccess: () => {},
      })
    );
  };

  useEffect(() => {
    dispatch(getStates({ onSuccess: () => {} }));
    dispatch(
      getJobListings({
        payload: null,
        page: storeActivePagination,
        onSuccess: () => {},
      })
    );
  }, []);
  return (
    <div className="bg-[#f5f5f5]">
      {loading && <Loader />}
      <Header />
      <FilterComponent storeActivePagination={storeActivePagination} />
      <div className="flex justify-content-between items-center bg-[#f5f5f5] nexaIqTab-container border-b">
        <h3 className="text-bold text-capitalize text-xl">Nexa {activeTab}</h3>
        <ul className="list-reset flex border-b">
          <li className="-mb-px mr-1">
            <a
              className={`border-b inline-block py-2 px-4 font-semibold  ${
                activeTab === "Jobs"
                  ? " border-l border-t border-r rounded-t  text-primary"
                  : "text-dark"
              }`}
              href="#"
              onClick={() => handleTabClick("Jobs")}
            >
              Jobs
            </a>
          </li>
          <li className="mr-1">
            <a
              className={`inline-block py-2 px-4 font-semibold ${
                activeTab === "Applied Jobs"
                  ? " border-l border-t border-r rounded-t text-primary"
                  : "text-dark"
              }`}
              href="#"
              onClick={() => handleTabClick("Applied Jobs")}
            >
              Applied Jobs
            </a>
          </li>
        </ul>
      </div>
      <div className="parent-container-wrapper">
        <div className="parent-container" style={{ position: "relative" }}>
          {jobs && jobs.length > 0 ? (
            jobs.map((job, index) => (
              <JobListing
                key={job?.id}
                id={job?.id}
                jobTitle={job.title}
                compensation={job.company || job?.organization?.name}
                location={job.location}
                descriptionPoints={job.description || job.job_description}
                companyName={job?.organization?.name}
                companyTagline={job?.organization?.address}
                CompanyDesc={job.CompanyDesc}
                logoUrl={job?.organization?.logo}
                postedDate={job.employment_type}
                badge={job.badge}
                jobProvider={job.source}
                activeTab={activeTab}
                organizationNumber={job?.organization?.phone}
                how_to_conduct={job.how_to_conduct}
                jobStatus={job.status}
                jobTonality={job.tonality}
                interview_deadline={job.interview_deadline}
                prescreening_deadline={job.prescreening_deadline}
              />
            ))
          ) : (
            <h2 className="text-center bg-[#f5f5f5] pt-6 text-3xl px-12 leading-10">
              {!jobs_found ? reason : "No Jobs Found"}
            </h2>
          )}
        </div>
        {jobs && jobs.length > 0 && (
          <div className="d-flex justify-center items-center w-100">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePaginationChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AutoApply;
