import React, { useEffect, useRef } from "react";
import CountUp from "react-countup";
import { FaHandshake, FaHouseUser, FaUsers } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Typed from "typed.js";
import newLanding from "../../assets/media/Landing/newlanding.jpg";

const HeroSection = () => {
  const navigate = useNavigate();
  const typedRef = useRef(null);

  useEffect(() => {
    const typed = new Typed(typedRef.current, {
      strings: [
        "Cybersecurity",
        "IT",
        "Data Analytics",
        "Software",
        "Sales",
        "Finance",
        "Marketing",
      ],
      typeSpeed: 50,
      backSpeed: 50,
      backDelay: 2000,
      startDelay: 500,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="hero-container">
      <div className="container hero-internal-container">
        <div className="gradient-right"></div>
        <div className="text-center hero-content">
          <h1 className="hero-heading color-dark">
            Unlock Faster Job Placements for Your Candidates with Nexa’s
            All-in-One Platform
          </h1>
          <p className="hero-description color-dark mt-4">
            Nexa Elevate® provides a comprehensive, AI-powered toolkit that
            takes care of every step from career preparation to job placement,
            seamlessly integrating into your training programs. We address your
            key pain points by offering personalized training paths, real-time
            feedback, and job-matching capabilities, ensuring that your learners
            are fully prepared and positioned to land jobs in
            <span>
              <h2 className="color-primary">
                <span ref={typedRef}></span>
              </h2>
            </span>
          </p>
          <br />
          <div
            className="button-group"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              flexWrap: "wrap",
              marginTop: "20px",
            }}
          >
            <Link to="/register" className="ic-btn">
              <button
                className="hero-button my-3"
                style={{
                  display: "inline-block",
                  padding: "10px 20px",
                  borderRadius: "25px",
                  textAlign: "center",
                }}
              >
                Start For Free
              </button>
            </Link>
            <a href="https://tally.so/r/3yDKdg" className="ic-btn">
              <button
                className="my-3"
                style={{
                  display: "inline-block",
                  padding: "10px 20px",
                  borderRadius: "25px",
                  textAlign: "center",
                  whiteSpace: "normal", // Allows text to wrap
                  lineHeight: "1.4", // Adjust line height for better readability
                  minWidth: "200px", // Ensure button has enough width
                }}
              >
                Career Center <br />
                Efficiency Assessment
              </button>
            </a>
          </div>
        </div>
        <div className="gradient-left"></div>
        <div className="mt-4 px-0 px-sm-5">
          <div className="mt-4 px-0 px-sm-5 h-sm-[500px] h-md-[750px] flex justify-center items-center">
            <img
              src={newLanding}
              className="transform transition-transform hover:scale-125 rounded-xl duration-500 h-[80%] w-[90%] md:w-[100%] bg-cover"
            />
          </div>
        </div>
        <div className="hero-counter-card d-flex justify-content-center align-items-center gap-6 flex-col md:flex-row">
          <div className="col-sm-12 col-md-4 hero-counter_box">
            <div className="hero-counter_box-inner d-flex justify-content-center align-items-center flex-col h-full w-full">
              <FaUsers size={65} />
              <CountUp end={724} duration={4} />
              <p>People helped land jobs</p>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 hero-counter_box">
            <div className="hero-counter_box-inner d-flex justify-content-center align-items-center flex-col h-full w-full">
              <FaHouseUser size={65} />
              <CountUp end={52} duration={4} />
              <p>Training Institutes that use nexa</p>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 hero-counter_box">
            <div className="hero-counter_box-inner d-flex justify-content-center align-items-center flex-col h-full w-full">
              <FaHandshake size={65} />
              <CountUp end={565} duration={4} />
              <p>Active job seekers</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
