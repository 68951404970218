import Repository from "./Repository";

const NexaIqRepository = {
  getJobListings(payload, page) {
    console.log(payload, "payload");
    if (!payload) {
      return Repository.get(`/smart_screen_jobs/search_jobs?page=${page}&size=10`);
    } 
    else if (payload.location && payload.from_date && payload.title) {
      return Repository.get(
        `/smart_screen_jobs/search_jobs?page=${page}&size=10&location=${payload.location}&from_date=${payload.from_date}&title=${payload.title}`
      );
    }
    else if (payload.location && payload.title) {
      return Repository.get(
        `/smart_screen_jobs/search_jobs?page=${page}&size=10&location=${payload.location}&title=${payload.title}`
      );
    } else if (payload.location && payload.from_date) {
      return Repository.get(
        `/smart_screen_jobs/search_jobs?page=${page}&size=10&location=${payload.location}&from_date=${payload.from_date}`
      );
    } else if (payload.from_date && payload.title) {
      return Repository.get(
        `/smart_screen_jobs/search_jobs?page=${page}&size=10&from_date=${payload.from_date}&title=${payload.title}`
      );
    } else if (payload.location) {
      return Repository.get(
        `/smart_screen_jobs/search_jobs?page=${page}&size=10&location=${payload.location}`
      );
    } else if (payload.title) {
      return Repository.get(
        `/smart_screen_jobs/search_jobs?page=${page}&size=10&title=${payload.title}`
      );
    } else if (payload.from_date) {
      return Repository.get(
        `/smart_screen_jobs/search_jobs?page=${page}&size=10&from_date=${payload.from_date}`
      );
    }
  },
  getSingleJob(id){
    return Repository.get(`/smart_screen_jobs/jobs/${id}`)
  }
};

export default NexaIqRepository;
