import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
const chatRepo = RepositoryFactory.get("chat");

export const startChatAction = createAsyncThunk(
  "chat/createChat",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await chatRepo.startChat(payload);
      if (data) {
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const sendMessageAction = createAsyncThunk(
  "chat/sendMessage",
  async ({ messageData, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await chatRepo.sendMessage(messageData);
      if (response.status === 200) {
        onSuccess(response.data);
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateChatMessages = createAsyncThunk(
  "chat/updateChatMessages",
  async (lastMessage, { getState, rejectWithValue }) => {
    console.log('lastMessage: ', lastMessage);
    try {
      const { messages } = getState().chat.singleChat;

      // Check if lastMessage.id exists in the messages array
      if (!messages.some((message) => message.id === lastMessage.id)) {
        // Add lastMessage to the array if it doesn't exist
        return [...messages, lastMessage];
      }

      // Return existing messages if lastMessage.id exists
      return messages;
    } catch (err) {
      return rejectWithValue(err.message || "An error occurred");
    }
  }
);
export const getAllChatAction = createAsyncThunk(
  "chat/getChats",
  async (_, { rejectWithValue }) => {
    try {
      const res = await chatRepo.getAllChats();
      let data = res?.data;
      if (data) {
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSingleChatAction = createAsyncThunk(
  "chat/singleChat",
  async (id, { rejectWithValue }) => {
    try {
      const res = await chatRepo.getSingleChat(id);
      let data = res?.data;
      if (data) {
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  loading: false,
  singleChatLoading: false,
  connectedChats: [],
  singleChat: null,
};

const ChatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //Get all roles
      .addCase(getAllChatAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllChatAction.fulfilled, (state, action) => {
        state.loading = false;
        state.connectedChats = action.payload;
        state.singleChat = null;
      })
      .addCase(getAllChatAction.rejected, (state, action) => {
        state.loading = false;
        state.connectedChats = [];
      })
      .addCase(getSingleChatAction.pending, (state) => {
        state.singleChatLoading = true;
        state.singleChat = null;
      })
      .addCase(getSingleChatAction.fulfilled, (state, action) => {
        state.singleChatLoading = false;
        state.singleChat = action.payload;
      })
      .addCase(getSingleChatAction.rejected, (state, action) => {
        state.singleChatLoading = false;
      })
      .addCase(updateChatMessages.pending, (state) => {
        state.singleChatLoading = true;
      })
      .addCase(updateChatMessages.fulfilled, (state, action) => {
        state.singleChatLoading = false;
        state.singleChat.messages = action.payload;
      })
      .addCase(updateChatMessages.rejected, (state, action) => {
        state.singleChatLoading = false;
      });
  },
});
export default ChatSlice.reducer;
